.fragrance-composition-things {
    position: relative;
    padding: 40px 0px;
}

.fragrance-composition-things-industry {
    position: relative;
    padding: 40px 0px;
    margin: 50px 0px 100px 0px;
}

.composition-things-title {
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.composition-things-title h1 {
    color: #c42625;
    margin: 0;
    font-weight: bold;
    line-height: 1;
}

.right-col-industry {
    position: absolute;
    right: 0;
    top: -170px;
}

.composition-things-title-industry h1 {
    color: #c42625;
    margin: 0;
}

.composition-things-title-industry .hr-line {
    background-color: #c42625;
    height: 7px;
    max-width: 100px;
    margin-top: 35px;
}

.composition-things-title-industry {
    margin-bottom: 15px;
}

.hr-line {
    background-color: #c42625;
    height: 7px;
    max-width: 100px;
}

.composition-row {
    justify-content: space-between;
    position: relative;
    margin: 10px 0px;
}

.composition-things-details p {
    text-indent: 28px;
    margin-bottom: 30px;
}

.fragrance-composition-things .composition-row {
    padding-top: 45px;
}

.composition-img img {
    height: 300px;
    max-width: 300px;
    border-radius: 50%;
} 
.topselling-section .topselling-button.download-button {
    text-align: left;
    position: absolute;
    top: 55px;
    z-index: 9;
}
.topselling-section .topselling-button.download-button .btn.resetbht {
    border-radius: 5px;
}
.composition-btn .my-composit-btn,
.topselling-section .download-button .btn {
    background-color: #c42625 !important;
    color: #fff;
    border: 3px solid black !important;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 32px;
}
.composition-btn .my-composit-btn:hover,
.topselling-section .download-button .btn:hover{
    background: #fff !important;
    color: #c42625 !important;
}
.composition-btn .my-composit-btn:hover a{
    color: #c42625 !important;
}
.composition-txt {
    text-align: right;
}
.composition-txt.composition-txt-position{
    position: absolute;
    right: 0;
    border-bottom: 1px solid #c42625;
}

.composition-txt h4 {
    color: #c42625;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.composition-notes {
    max-width: 100%;
    margin-top: 50px;
    line-height: 39px;
}

.top-note, .middle-note, .bottom-note {
    margin-bottom: 30px;
}

.top-note .note {
    color: black;
    font-weight: 600;
    margin-bottom: 0px;
}

.middle-note .note {
    color: black;
    font-weight: 600;
    margin-bottom: 0px;
}

.bottom-note .note {
    color: black;
    font-weight: 600;
    margin-bottom: 0px;
}

.top-note .note span {
    cursor: pointer;
    border-bottom: 2px solid black;
}

.middle-note .note span {
    cursor: pointer;
    border-bottom: 2px solid black;
}

.bottom-note .note span {
    cursor: pointer;
    border-bottom: 2px solid black;
}

.top-note, .middle-note, .bottom-not, .example {
    color: #c42625;
    font-weight: 600;
}
.composition-rightside {
    text-align: center;
    max-width: 350px;
    margin: 0 auto;
    position: relative;
}
.composition-rightside .composition-img, .composition-rightside .composition-btn {
    margin-bottom: 30px;
}

/*********Top Selling Page********/
.topselling-section {
    text-align: center;
    padding: 40px 0;
    position: relative;
}
.topselling-section .topselling-title h1, .topselling-section .topselling-title h2 {
    text-transform: uppercase;
    font-weight: bold;
    color: #c42625;
    margin-bottom: 35px;
    position: relative;
}
.topselling-section .topselling-title h1:after, .topselling-section .topselling-title h2:after{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -15px;
    height: 7px;
    width: 60px;
    background: #c42625;
    margin: 0 auto;
}
.topselling-details.topselling-discription {
    text-align: left;
}
.topselling-section .container {
    max-width: 1100px;
}
.topselling-section .topselling-fragrance {
    margin-bottom: 50px;
}
.topselling-section .topselling-fragrance:last-child{
    margin-bottom: 0;
}
.topselling-fragrance .topselling-details li {
    margin-bottom: 10px;
}
.topselling-fragrance .topselling-details li span {
    color: #c42625;
}
.topselling-fragrance .topselling-details ul{
    margin: 0;
    padding: 0;
}
.topselling-details p span {
    font-weight: bold;
}
.topselling-image img {
    max-width: 75%;
    max-height: 300px;
}


@media (max-width: 1199px){
    .composition-img img {
        height: auto;
        max-width: 100%;
    }
}
@media (max-width: 991px){
    .composition-things-details p {
        margin-bottom: 20px;
    }
    .composition-notes {
        line-height: 32px;
    }
    .fragrance-composition-things {
        padding: 40px 0px 10px;
    }
    .composition-txt.composition-txt-position h4 br{
        display: none;
    }
    .composition-rightside .composition-img, .composition-rightside .composition-btn {
        margin-bottom: 25px;
    }
    .composition-txt h4 {
        margin-bottom: 20px;
    }
    .fragrance-composition-things .composition-row {
        padding-top: 30px;
    }
    .hr-line {
        height: 5px;
        max-width: 70px;
    }
    .topselling-image img {
        max-height: 240px;
    }
    .topselling-section .topselling-fragrance {
        margin-bottom: 30px;
    }
    .topselling-section .topselling-button.download-button {
        top: 34px;
    }
}
@media (max-width: 767px){
    .composition-rightside {
        max-width: 100%;
    }
    .composition-txt.composition-txt-position {
        position: static;
    }
    .composition-txt {
        text-align: center;
    }
    .composition-txt h4 br{
        display: none;
    }
    .composition-rightside .composition-img, .composition-rightside .composition-btn {
        margin-bottom: 20px;
    }
    .top-note, .middle-note, .bottom-note {
        margin-bottom: 10px;
    }
    .composition-notes {
        margin-top: 30px;
    }
    .composition-txt h4{
        margin-bottom: 20px;
    }
    .composition-notes {
        line-height: 25px;
    }
    .fragrance-composition-things {
        padding: 30px 0px 10px;
    }
    .composition-things-title {
        margin-bottom: 10px;
    }
    .fragrance-composition-things .composition-row {
        padding-top: 25px;
    }
    .topselling-fragrance .download-button {
        margin-top: 20px;
    }
    .topselling-fragrance .topselling-image {
        margin-top: 20px;
    }
    .topselling-image img {
        max-height: 200px;
    }
    .topselling-section {
        padding: 30px 0;
    }
    .topselling-fragrance .topselling-details li {
        margin-bottom: 5px;
    }
}
@media (max-width: 574.98px){
    .composition-img img {
        height: 200px;
        max-width: 200px;
    }
    .topselling-section .topselling-button.download-button {
        position: static;
        margin-bottom: 10px;
        text-align: center;
    }
    .topselling-fragrance .topselling-image {
        margin-top: 15px;
    }
    .topselling-fragrance .topselling-details li{
        font-size: 18px;
    }
}