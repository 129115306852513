.modal {
    padding-left: 0px !important;
}

@media (max-width: 1930px) {
    .login-form-holder iframe {
        height: 450px !important;
    }
}

@media (max-width: 991px) {
    .login-form-holder iframe {
        height: 330px !important;
    }
}

@media (max-width: 768px) {
    .login-form-holder iframe {
        height: 270px !important;
    }
}

@media (max-width: 425px) {
    .login-form-holder iframe {
        height: 210px !important;
    }
    .modal-header {
        font-size: 18px;
    }
}

@media (max-width: 375px) {
    .login-form-holder iframe {
        height: 170px !important;
    }
}