@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-Bold.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-BoldItalic.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro Book';
    src: url('/src/assets/fonts/BebasNeuePro-BookItalic.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro Book';
    src: url('/src/assets/fonts/BebasNeuePro-Book.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-LightItalic.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-Italic.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-Light.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-ThinItalic.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-Regular.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bebas Neue Pro';
    src: url('/src/assets/fonts/BebasNeuePro-Thin.woff2') format('woff2'), url('/src/assets/fonts/BebasNeuePro-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url('/src/assets/fonts/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Light';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Light'), url('/src/assets/fonts/MyriadPro-Light.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Semibold'), url('/src/assets/fonts/MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Bold'), url('/src/assets/fonts/MYRIADPRO-BOLD.woff') format('woff');
}

html body {
    font-family: 'Bebas Neue Pro';
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0;
    color: #000;
    font-weight: 500;
}

html body .btn.resetbht {
    font-size: 30px;
    line-height: 1.2;
    font-family: "Bebas Neue Pro", sans-serif;
    padding: 8px 20px;
    text-decoration: none;
    border-radius: 20px;
}

.img-responsive {
    width: 100%;
}

html body .pr-0 {
    padding-right: 0px;
}


/* .container {
    max-width: 1575px;
} */

section.sticky-header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.align-center {
    align-items: center;
}

.mt-50 {
    margin-top: 50px;
}

.mt-30 {
    margin-top: 30px;
}

.sec-padding {
    padding: 50px 0px 0;
}

.pb-50 {
    padding-bottom: 50px;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Bebas Neue Pro';
}

body h1 {
    color: black;
    font-size: 60px;
    line-height: 1.2;
    letter-spacing: 2.5px;
}

body h2 {
    color: #c42625;
    font-size: 44px;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: bold;
    letter-spacing: 1px;
}

body h3 {
    line-height: 1.2;
    font-size: 35px;
}

body h4 {
    font-size: 30px;
    line-height: 1.2;
}

body h5 {
    font-size: 25px;
    line-height: 1.2;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: none !important;
}

a.btn-href {
    font-size: 35px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0px;
    background: #c42625;
    max-width: 400px;
    width: 100%;
    display: block;
    color: white;
    text-decoration: none;
}

.logo-wrap img {
    max-width: 220px;
    margin: 0px 0px 0px 0px;
    display: block;
}

body .btn-danger {
    background-color: #c42625;
    border-color: #c42625;
}


/* header {
    margin-bottom: 25px;
} */

header .navbar-light .navbar-nav .nav-link {
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0px;
    /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
    width: 25%;
    background: #c42625;
    color: #fff;
    border: 3px solid #000;
    margin-right: 20px;
}

header .navbar-light .navbar-nav .nav-link:last-child {
    margin-right: 0;
}

header .navbar-light .navbar-nav .nav-link:hover {
    background-color: #000;
    color: #fff;
}

.menu-wrap nav.navbar {
    padding: 20px 0 0;
}

header .nav-item.dropdown {
    background: #c42625;
    width: 25%;
    position: static;
    margin-right: 20px;
}

.menu-wrap .navbar-expand-lg .navbar-nav .dropdown-menu {
    column-count: 3;
}

header .nav-item.dropdown a.nav-link {
    width: 100%;
}

header .dropdown-menu {
    width: 100%;
    border: 2px solid red;
    border-radius: 0;
}

header .dropdown-menu.show {
    margin-top: 0;
}

header .dropdown-menu a {
    display: block;
    /* padding: 6px 30px; */
    color: #c42625;
    text-shadow: -1px -1px 0 #000000ab, 1px -1px 0 #0000, -1px 0px 0 #0000, 0px 0px 0 #0000;
    text-decoration: none;
    position: relative;
}

header .me-auto.navbar-nav {
    width: 100%;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
}

.header-info h2 {
    margin: 0;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    font-size: 84px;
    line-height: 73px;
    letter-spacing: 0.6px;
}

.top-header .header-head {
    align-items: center;
}


/* .top-header .head-searchbar{
    padding-left: 0;
} */

.search-wrap {
    width: 100%;
    margin: 0 auto;
    margin-right: 0;
}

.search-wrap form {
    display: flex;
}

.search-wrap input[type=search] {
    padding: 3px 15px;
    /*font-size: 22px;*/
    border: 3px solid #000000;
    border-left: none!important;
    width: 87%;
    background: white;
    outline: 0;
    font-size: 25px;
    color: black;
    height: 50px;
}

.search-wrap select {
    border: 3px solid #000000;
    border-right: 1px solid #e8e8e8!important;
    width: 42%;
    /*text-align: center;*/
    border-radius: 0px;
}

textarea:focus,
.form-control:focus,
.form-select:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: #c42625!important;
    box-shadow: 0 1px 1px #fa7473 inset, 0 0 5px #fa5a59;
    outline: 0 none;
}

.shadow-must {
    border-color: #c42625!important;
    box-shadow: 0 1px 1px #fa7473 inset, 0 0 5px #fa5a59!important;
    outline: 0 none;
}

.search-wrap input[type=search]::-webkit-search-cancel-button {
    color: red;
}

.search-wrap input[type=search]::placeholder {
    color: #cccccc;
    /*text-align: center;*/
    font-weight: 500;
}

.profile-user.dropdown {
    padding-right: 30px;
}

.profile-user .dropdown-basic-head {
    outline: none;
    box-shadow: none !important;
    position: relative;
}

.profile-user .dropdown-basic-head:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    right: -30px;
    top: 50%;
    font-size: 27px;
    color: #c42625;
    transform: translateY(-50%);
}

.search-wrap button {
    padding: 0 15px;
    background: #cccccc;
    color: black;
    font-size: 24px;
    border: 3px solid #000000;
    border-left: none;
    cursor: pointer;
    height: 50px;
}

header .profile-user .dropdown-menu.show {
    background: #fff;
    border-color: #c42625;
    padding: 5px 0;
    right: 0;
    left: inherit;
}

header .profile-user .dropdown-menu.show .dropdown-item {
    color: #c42625;
    padding: 4px 10px;
    line-height: 1;
    margin-bottom: 6px;
    font-size: 20px;
}

.search-wrap::after {
    content: "";
    clear: both;
    display: table;
}

.menu-wrap .navbar-nav .navbar-item {
    width: 25%;
    background: #c42625;
    margin: 0 auto;
    margin-right: 20px;
}

.menu-wrap .navbar-nav .navbar-item:nth-child(4) {
    margin-right: 0px;
}

.dropdown-toggle.btn-primary {
    width: 100%;
    height: 100%;
    font-size: 46px;
    background: transparent;
    border: 0;
    text-transform: uppercase;
    outline: 0;
    box-shadow: none;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.menu-wrap .dropdown-menu {
    font-size: 1.5rem;
}

.dropdown-toggle.btn-primary:hover,
.btn-primary:focus {
    color: rgba(0, 0, 0, .55);
    background: transparent !important;
    box-shadow: none !important;
}

.show>.btn-primary.dropdown-toggle {
    background: transparent !important;
    box-shadow: none !important;
}

.menu-wrap .navbar-nav .navbar-item>a {
    color: white;
    font-size: 46px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 0px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.banner-wrap {
    background-size: cover;
    background-position: right center;
    position: relative;
    background-repeat: no-repeat;
    border: 10px solid #000;
}

.banner-wrap .banner-video {
    text-align: right;
    position: relative;
}

.banner-wrap .banner-video:after {
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    background: rgb(196, 38, 37);
    background: linear-gradient(90deg, rgba(196, 38, 37, 1) 0%, rgba(196, 38, 37, 1) 34%, rgba(255, 255, 255, 0) 43%);
    z-index: 9;
    top: 0;
    bottom: 0;
}

.banner-wrap .banner-video video {
    height: 425px;
    vertical-align: top;
    width: 100%;
    max-width: 68%;
    object-fit: cover;
}

.banner-wrap .banner-description {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 9;
    width: 100%;
    transform: translateY(-50%);
}

.banner-wrap .banner-info {
    max-width: 430px;
    background: white;
    padding: 30px 30px 40px;
    text-align: center;
    border-radius: 20px;
    position: relative;
    box-shadow: -6px 15px 0px 5px #000;
}

.banner-description {
    position: relative;
}

.banner-description .line {
    max-width: 600px;
    height: 95%;
    position: absolute;
    width: 100%;
    left: 2%;
    background: white;
    margin: auto;
    top: 0;
    bottom: 0;
    border: 2px solid black;
}

.banner-wrap .banner-description .container {
    max-width: 1480px;
    padding: 0 30px;
}

.banner-wrap .banner-info h2 {
    letter-spacing: 0;
    font-weight: 600;
    margin-bottom: 22px;
    color: #000;
    text-transform: uppercase;
}

.banner-wrap .banner-info h2 span {
    display: block;
}

.banner-wrap .banner-info input::placeholder {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
}

.banner-wrap .banner-info input {
    border: 2px solid #000;
    padding: 12px;
    margin-top: 25px;
    margin: 0 auto;
    color: #000;
    font-family: "Bebas Neue Pro", sans-serif;
    font-size: 21px;
    padding-right: 55px;
    border-radius: 8px;
    text-align: center;
}

.grow-form {
    position: relative;
}

.grow-form button {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 14px;
    right: 10px;
}

.grow-form button img {
    max-width: 30px;
}

.courseCategories-wrap {
    margin-right: 0%;
    position: relative;
    padding-bottom: 15px;
}

.ol-line {
    background: #9e9e9e;
    width: 98%;
    height: 2px;
    margin: auto;
}

.courseCategories-wrap .item h2 {
    font-size: 22px;
    border: 4px solid #c42625;
    text-align: center;
    line-height: 21px;
    margin-top: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    height: 70px;
    justify-content: center;
    letter-spacing: 0;
}

.courseCategories-wrap .slick-next {
    right: -40px;
}

.courseCategories-wrap .item {
    padding: 0px 15px;
}

.courseCategories-wrap .item h2 a {
    color: #c42625;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    padding: 21px 10px;
    width: 100%;
    text-transform: capitalize;
}

.courseCategories-wrap .item h2:hover {
    background-color: #94908d;
    color: #fff;
}

.courseCategories-wrap .owl-prev {
    opacity: 0;
}

.courses-wrap.main-categories.sec-padding .thumbnail img,
.courses-wrap.main-categories.main-resources .thumbnail img {
    max-width: 220px;
}

.owl-theme .owl-nav {
    position: absolute;
    top: 0;
    right: 0;
}

.hr {
    height: 5px;
}

.courseCategories-wrap .owl-theme .owl-nav [class*=owl-] {
    position: absolute;
    font-size: 9em;
    line-height: 0;
    color: #c42625 !important;
    margin-left: 0.4em;
    outline: 0;
}

.owl-carousel.owl-theme {
    width: 90%;
}

.category-info {
    background: #c42625;
    padding: 25px 0px 25px;
    margin-top: 10px;
}

.category-info img {
    max-width: 190px;
}

.category-info img.dot {
    max-width: 275px;
    margin-left: auto;
    display: block;
}

.img-right {
    text-align: right;
}

.category-info h3 {
    color: white;
    list-style: none;
    text-transform: uppercase;
    font-size: 35px;
    letter-spacing: 2px;
    font-weight: bold;
    font-family: "Bebas Neue Pro", sans-serif;
    margin-bottom: 15px;
    line-height: 35px;
}

.category-info ul {
    padding: 0;
    margin: 0 0 12px 0;
}

.category-info ul li {
    color: white;
    list-style: none;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 2px;
    font-weight: 400;
    font-family: "Bebas Neue Pro", sans-serif;
    margin-bottom: 0;
}

.category-info a {
    padding: 4px 5px 1px;
    text-transform: capitalize;
    font-size: 26px;
    color: #000;
    font-family: "Bebas Neue Pro", sans-serif;
    text-align: center;
    background: #fff;
    text-decoration: none;
    display: block;
    max-width: 361px;
    border-radius: 8px;
    box-shadow: -5px 6px 0 #000;
}

.category-info a:hover {
    color: #c42625 !important;
}

.courses-wrap .course-item .thumbnail img {
    max-width: inherit;
    width: 100% !important;
    height: auto !important;
}

.courses-wrap .course-item h4 {
    margin: 0px 0 10px;
    color: #000;
    text-align: center;
    font-weight: 600;
}

.courses-wrap.sec-padding {
    padding: 20px 0px 0;
}

.fragrance-info.border_overall_15 {
    border: 7px solid #94908d;
}

.fragrance-u .fragrance-info {
    text-align: center;
    padding: 20px;
    margin: 40px 0 0;
}

.main-categories h3 {
    margin-bottom: 30px;
    letter-spacing: 1px;
    color: #000000;
    font-weight: 700;
    text-align: left;
}

.main-categories .flex-wrap {
    justify-content: center;
}

.right-custom-search .form-inline {
    display: flex;
    max-width: 500px;
    justify-content: flex-end;
}

.d-flex.right-align {
    justify-content: flex-end;
}

.right-custom-search .form-inline .input-wrap {
    width: 85%;
}

.right-custom-search .form-inline .input-wrap input {
    border-radius: 0px;
}

.right-custom-search .form-inline .button-wrap {
    width: 15%;
}

.right-custom-search .form-inline .button-wrap .btn {
    width: 100%;
    border-radius: 0px;
}

.fragrance-info h2 {
    letter-spacing: 1px;
    color: #c41425;
    font-weight: 700;
    text-align: center;
    margin: 0 0 15px;
}

.fragrance-info h3 {
    font-size: 35px;
    letter-spacing: 1px;
    text-transform: capitalize;
    line-height: 1.2;
    color: #000000;
    font-weight: 500;
    font-family: "Bebas Neue Pro", sans-serif;
    text-align: center;
    max-width: 880px;
    margin: 0 auto;
}

.courses-wrap.main-categories .course-item {
    text-align: center;
}

.main-resources .course-item {
    font-size: 32px;
    text-align: center;
}

.main-categories .course-item .thumbnail {
    border: 10px solid #c42625;
    border-radius: 25px;
    overflow: hidden;
}

.course-item .thumbnail h4 {
    font-size: 22px;
    letter-spacing: 0;
    color: #c41425;
    font-weight: 700;
    font-family: "Bebas Neue Pro", sans-serif;
    text-align: center;
    line-height: 22px;
    min-height: 55px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.courses-wrap.main-categories.main-resources {
    margin-bottom: 70px;
    margin-top: 70px;
}

.courses-wrap.main-categories.main-resources .thumbnail {
    height: auto;
}

.courses-wrap.main-categories.main-resources .thumbnail {
    border: 10px solid #c42625;
    border-radius: 25px;
}

.courses-wrap.main-categories.main-resources .thumbnail img {
    border: 0px;
    border-radius: 0px;
}

.courses-wrap.main-categories.main-resources h2 {
    font-size: 35px;
}

footer {
    background: #94908d;
    padding: 25px 0 50px;
}

footer .quick-lins-fu {
    display: flex;
    flex-wrap: wrap;
}

footer .bottom-footer {
    margin-top: 115px;
}

.bottom-footer h2 {
    text-align: right;
    margin-bottom: 10px !important;
}

footer hr {
    margin: 2rem 0px;
    margin-bottom: 1rem;
    border: 0;
    border-top: 12px solid white;
    opacity: 1;
}

footer ul {
    padding: 0;
}

footer ul li {
    margin-bottom: 10px;
}

footer ul li a,
footer ul li span {
    font-size: 45px;
    letter-spacing: 0px;
    color: #000000;
    font-family: "Bebas Neue Pro", sans-serif;
    font-weight: 400;
    text-decoration: none;
    line-height: 45px;
    text-transform: capitalize;
}

footer ul li a:hover {
    color: #c42625;
}

.footer-widget.widget-3 {
    text-align: center;
}

.footer-right-column .footer-widget.widget-3 {
    text-align: right;
}

footer .footer-right-column .footer-widget.widget-3 h2 {
    text-align: right;
}

.bottom-footer .footer-widget.widget-3 {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.bottom-footer .footer-widget.widget-1 {
    text-align: left;
}

html body footer .top-inner {
    display: flex;
    justify-content: space-between;
    border-top: 5px solid white;
    margin: 0 auto 20px;
}

button.slick-arrow.slick-next {
    background: url(/src/assets/img/slick-right-arrow.png) no-repeat;
    width: 40px;
    height: 40px;
    background-size: contain;
}

button.slick-arrow.slick-prev {
    background: url(/src/assets/img/slick-left-arrow.png) no-repeat;
    width: 40px;
    height: 40px;
    background-size: contain;
}

button.slick-prev:before,
button.slick-next:before {
    content: none;
}

.social-wrap .footer-widget.widget-3 a {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: black;
    color: white;
    line-height: 50px;
    font-size: 28px;
    margin: 0 0 0 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.social-wrap .footer-widget.widget-3 a:hover {
    background: #c42625;
}

.footer-widget.widget-1 p {
    color: #bababa;
    font-size: 32px;
    font-weight: 400;
    text-transform: uppercase;
}

.bottom-footer-copyright {
    position: absolute;
    bottom: 0;
    left: 12px;
}

footer .container {
    position: relative;
}

.pages-info {
    background-color: white;
    padding: 12px 5%;
    text-align: center;
}

.pages-info p {
    margin: 0;
    font-size: 37px;
    list-style: none;
    text-transform: capitalize;
    letter-spacing: 3px;
}

.pages-info p a {
    color: black;
    text-decoration: none;
}

.video-wrap {
    background: black;
    padding: 80px 0px;
    color: white;
}

.aboutCourse-wrap h2 {
    color: black;
    font-size: 45px;
    text-transform: uppercase;
    line-height: 50px;
    margin-bottom: 5px;
}

.course-info ul {
    padding: 0px;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9);
    text-shadow: none;
}

.course-info {
    display: flex;
    flex-wrap: wrap;
    align-items: inherit;
    max-width: 98%;
    border: 2px solid gray;
    padding: 30px;
}

.course-info .course-name {
    flex-basis: 30%;
}

.course-info .course-des {
    flex-basis: 70%;
}

.course-info .course-des .course-detail {
    border-top: 2px solid gray;
    padding-top: 50px;
}

.courses-list {
    border: 2px solid gray;
    padding: 30px;
    max-width: 350px;
    margin-left: auto;
}

.courses-list ul {
    padding: 0;
}

.lectures-list {
    padding-bottom: 70px;
}

.lectures-list p {
    margin-bottom: 0;
    color: lightgray;
    font-size: 18px;
}

.lectures-list h2 {
    color: black;
    font-size: 40px;
    font-weight: 700;
    text-align: center;
}

.lectures-list .course-outline .lectures-list {
    padding-bottom: 50px;
    border-bottom: 2px solid grey;
    max-width: 750px;
    margin-bottom: 15px;
}

.lectures-list .course-outline .lectures-list img {
    max-width: 22px;
}

.lectures-list .heading-wrap {
    max-width: 100px;
    text-align: center;
    margin: 0 auto;
}

.video-wrap.sec-padding {
    background: black;
    padding: 70px 0px 130px;
    color: white;
}

.video-wrap.sec-padding .video-text {
    max-height: 450px;
    overflow: auto;
}

.video-wrap.sec-padding li span {
    float: right;
}

.video-wrap.sec-padding h3 {
    margin-bottom: 30px;
}

.about-course {
    padding-bottom: 50px;
}

.about-course:nth-last-child(1) {
    padding-bottom: 0px;
}

.might-like {
    padding-bottom: 70px;
}

.might-like h3 {
    text-align: right;
    margin-bottom: 30px;
}

.might-like a {
    text-decoration: none;
}

.sec-padding.aboutThis-course {
    padding: 70px 0px 0px;
}

.video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 60px;
    overflow: hidden;
}

.video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-text.mt-50 ul {
    padding: 0;
}

.top-header {
    padding: 30px 5px;
}

html body header .navbar-light .navbar-nav .nav-link:focus,
html body header .navbar-light .navbar-nav .nav-link:hover {
    color: #fff;
    text-shadow: none;
}

.search-wrap button img {
    width: auto;
    max-width: 35px;
}

.top-header .row {
    padding: 0;
}

.top-header .header-head {
    align-content: center;
}

.category-top-box {
    margin: 0 10px;
    width: 18%;
}

.after-bar {
    position: relative;
}

.after-bar:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 6px;
    right: 0;
    background: #94908d;
    top: 20px;
}

.after-bar span {
    position: relative;
    background-color: #fff;
    z-index: 9;
    padding: 0 20px 0 0;
    text-transform: uppercase;
}

.course-main-video {
    width: 35%;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    align-content: center;
    justify-content: center;
    position: relative;
}

.login-form-holder {
    width: 100%;
}

.login-form-holder .login-form {
    margin: 24px auto 0;
    max-width: 320px;
}

.thumbnail .fa-youtube-play {
    font-size: 60px;
    position: absolute;
    padding: 0;
    color: red;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.course-item .thumbnail {
    position: relative;
}

.thumbnail .fa-youtube-play:hover {
    color: white;
    cursor: pointer;
}


/*********COURSE DETAIL PAGE**********/

.course-detail-sidebar {
    border: 2px solid #000;
    border-radius: 25px 25px 0 0;
    overflow: hidden;
    height: 100%;
    font-family: 'Bebas Neue Pro';
}

.course-detail-sidebar .course-profile-section {
    display: flex;
    break-after: column;
    padding: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.course-detail-sidebar .course-profile-section {
    display: flex;
    break-after: column;
    padding: 20px;
    flex-wrap: wrap;
    background: #4a4744;
}

.course-detail-sidebar .course-profile-section .profile-image {
    width: 160px;
    border: 6px solid #000;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 15px;
}

.course-detail-sidebar .profile-detail {
    width: calc(100% - 175px);
}

.course-detail-sidebar .profile-detail h3 {
    letter-spacing: 0;
    font-weight: bold;
    color: #fff;
    margin: 0 0 5px;
    text-transform: capitalize;
}

.course-detail-sidebar .profile-detail p {
    margin: 0;
    font-size: 25px;
    font-weight: 400;
    line-height: 30px;
    color: #94908d;
}

.course-detail-sidebar .course-detail-tabs .lesson-peragraph {
    padding: 20px;
    position: relative;
}

.course-detail-sidebar .course-detail-tabs .lesson-peragraph:after {
    content: '';
    position: absolute;
    width: 70%;
    height: 2px;
    left: 0;
    margin: 0 auto;
    bottom: 0;
    background: #9b9795;
    right: 0;
}

.courses-leftbar .course-import {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.courses-leftbar .course-import .import-detail,
.courses-leftbar .course-import .import-buttons {
    width: 50%;
}

.courses-leftbar .course-import .import-detail h3 {
    font-size: 30px;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.courses-leftbar .course-import .import-detail p {
    font-size: 19px;
    line-height: 23px;
    color: #000;
    font-family: "Bebas Neue Pro", sans-serif;
}

.courses-leftbar .course-import .import-buttons ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.courses-leftbar .course-import .import-buttons ul li {
    padding: 0 5px;
}

.courses-leftbar .course-import .import-buttons ul li:last-child {
    padding-right: 0;
}

.courses-leftbar .course-import .import-buttons ul li .btn {
    padding: 5px 9px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: #c21626;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Bebas Neue Pro';
    width: 100px;
    font-size: 16px;
    border-radius: 15px;
}

.courses-leftbar .course-import .import-buttons ul li .btn:hover {
    background: #000 !important;
}

.courses-leftbar .course-import .import-buttons ul li .btn span {
    display: flex;
    width: 100%;
    justify-content: center;
}

.courses-leftbar .course-import .import-buttons ul li .btn span:first-child {
    font-size: 23px;
}

.course-detail-sidebar .course-detail-tabs ul {
    margin: 0 !important;
    background: #4a4744;
    padding: 0 20px 10px;
    border-bottom: 10px solid #94908d;
}

.course-detail-sidebar .course-detail-tabs ul li.nav-item button {
    padding: 0;
    color: #c0c0c0;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    border: 0;
    background: transparent;
    font-family: 'Bebas Neue Pro';
    position: relative;
}

.course-detail-sidebar .course-detail-tabs ul li.nav-item button.active {
    color: #fff;
}

.course-detail-sidebar .course-detail-tabs ul li.nav-item button.active:after {
    content: '';
    height: 10px;
    width: 100%;
    position: absolute;
    bottom: -19px;
    background: #c21626;
    left: 0;
}

.course-detail-sidebar .course-detail-tabs ul li.nav-item {
    padding-right: 15px;
}

.course-detail-tabs .lesson-peragraph p {
    color: #000;
    font-family: 'Bebas Neue Pro';
    line-height: 22px;
    font-size: 18px;
    letter-spacing: 0.2px;
    margin: 0;
}

.course-detail-tabs .course-video-list {
    margin: 20px;
    max-height: 370px;
    overflow: hidden;
    overflow-y: auto;
}

.course-detail-tabs .lesson-list {
    max-height: 305px;
    overflow: hidden;
    overflow-y: auto;
    margin: 10px 10px 20px;
}

.course-detail-tabs .course-video-list .video-list-deials {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.course-detail-sidebar .course-detail-tabs .video-section {
    width: 150px;
    border: 2px solid #c21626;
    height: 90px;
    margin-right: 25px;
}

.course-detail-tabs .course-video-list .video-list-deials .video-discription {
    width: calc(100% - 175px);
}

.course-detail-tabs .course-video-list .video-discription h2 {
    color: #000;
    font-size: 25px;
    letter-spacing: 0.5px;
    margin: 0;
    line-height: 30px;
}

.courses-page .courses-section .course-detail-top {
    padding-bottom: 35px;
    border-bottom: 4px solid #b8b5b3;
}

.course-detail-tabs .all-notes {
    padding: 25px 20px;
    background: #4a4744;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #000;
}

.course-detail-tabs .notes-show {
    display: block;
    position: relative;
    padding-left: 45px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: 'Bebas Neue Pro';
    color: #fff;
    text-transform: capitalize;
}

.course-detail-tabs .notes-show input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.course-detail-tabs .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 35px;
    border: 2px solid #000;
    width: 35px;
    background-color: #fff;
    border-radius: 10px;
}

.notes-show input:checked~.checkmark {
    background-color: #fff;
}

.course-detail-tabs .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.notes-show input:checked~.checkmark:after {
    display: block;
}

.course-detail-tabs .notes-show .checkmark:after {
    left: 11px;
    top: 6px;
    width: 9px;
    height: 16px;
    border: solid #c21626;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.lesson-list .lesson-list-items .lesson-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 5px;
    border-bottom: 2px solid #000;
    margin-bottom: 10px;
}

.lesson-list .lesson-list-items {
    padding: 10px;
    font-family: 'Bebas Neue Pro';
    margin-bottom: 5px;
}

.lesson-list .lesson-list-items .lesson-heading h3,
.lesson-list .lesson-list-items .lesson-heading h4 {
    font-size: 23px;
    margin: 0;
    line-height: 28px;
    text-transform: capitalize;
    position: relative;
}

.lesson-list .lesson-list-items .lesson-heading h4 {
    color: #94908d;
}

.lesson-list .lesson-list-items .lesson-heading h4:after {
    content: '';
    height: 15px;
    width: 15px;
    position: absolute;
    left: -25px;
    background: #c21626;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100px;
}

.lesson-list .lesson-list-items .lesson-discriptions p {
    margin: 0;
    font-size: 22px;
    line-height: 27px;
    max-width: 85%;
    font-weight: 500;
}

.other-course-video {
    padding: 25px;
}

.other-course-video .other-video-title h3 {
    margin: 0;
    color: #000;
    letter-spacing: 0;
    font-weight: bold;
    text-transform: capitalize;
}

.other-course-video .other-video-title {
    margin-bottom: 10px;
}

.other-course-video .video-section {
    border: 2px solid #c21626;
    background: #000;
}

.other-course-video .video-discription {
    margin-top: 10px;
    text-align: center;
}

.other-course-video .video-discription h3 {
    margin: 0;
    text-transform: capitalize;
    font-size: 25px;
}

.course-script-section {
    border: 2px solid #94908d;
    padding: 30px 25px 30px;
    font-family: 'Bebas Neue Pro';
    color: #000;
    margin: 40px 0;
}

.course-script-section .course-script-heading h4 {
    font-size: 31px;
    font-weight: 600;
}

.course-script-section .course-script-discription {
    max-height: 290px;
    overflow-y: auto;
    position: relative;
    background-image: url("/src/assets/img/tranparent-logo.png");
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: center;
}

.course-script-section .course-script-discription::-webkit-scrollbar-track,
.course-detail-tabs .course-video-list::-webkit-scrollbar-track,
.course-detail-tabs .lesson-list::-webkit-scrollbar-track,
.afi-diy-video .course-video-list::-webkit-scrollbar-track {
    border-radius: 0;
    background: #94908d;
    width: 9px;
}

.course-script-section .course-script-discription::-webkit-scrollbar,
.course-detail-tabs .course-video-list::-webkit-scrollbar,
.course-detail-tabs .lesson-list::-webkit-scrollbar,
.afi-diy-video .course-video-list::-webkit-scrollbar {
    width: 9px;
    background: #94908d;
}

.course-script-section .course-script-discription::-webkit-scrollbar-thumb,
.course-detail-tabs .course-video-list::-webkit-scrollbar-thumb,
.course-detail-tabs .lesson-list::-webkit-scrollbar-thumb,
.afi-diy-video .course-video-list::-webkit-scrollbar-thumb {
    background: #4a4744;
    border-radius: 0;
    border-color: #4a4744;
    width: 9px;
}

.course-script-section .course-script-discription p {
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
    white-space: pre-wrap;
    position: relative;
    z-index: 9;
}

.course-script-section .course-script-discription:after {
    content: '';
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    z-index: 1;
    opacity: 0.2;
    transform: translateY(-50%);
}

.course-script-section .course-script-heading {
    margin-bottom: 10px;
    text-align: center;
}

.course-detail-sidebar .course-detail-tabs {
    position: relative;
}

.course-detail-sidebar .course-detail-tabs .total-lessons {
    position: absolute;
    right: 20px;
}

.course-detail-sidebar .course-detail-tabs .total-lessons p {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    color: #94908d;
}

th.action-tablehead {
    text-align: center;
}

.active-detele-btns {
    display: flex;
    align-items: center;
    width: 200px;
    justify-content: flex-end;
    margin: 0 auto;
}

.pull-right.roles-right .active-detele-btns {
    width: 270px;
    margin-left: auto;
    margin-right: inherit;
}

td.course-table .eidt-delete-buttons {
    width: 285px;
}

.footer-widget .afi-image a {
    font-size: 45px;
    letter-spacing: 0px;
    color: #000000;
    font-family: "Bebas Neue Pro", sans-serif;
    font-weight: bold;
    text-decoration: none;
    line-height: 45px;
}

.footer-widget .afi-image a:hover {
    color: #c42625;
}

footer ul {
    margin: 0;
}

.footer-widget .afi-image {
    text-align: center;
    padding-top: 15px;
}

.course-detail-sidebar .course-detail-tabs .video-section {
    border: 0;
}

.course-detail-sidebar .course-detail-tabs .video-section iframe {
    height: 420px;
}

footer h2 {
    margin: 0 0px 10px;
    font-size: 50px;
    line-height: 50px;
    letter-spacing: 0;
    font-weight: 600;
    color: #fff;
    text-transform: capitalize;
}

footer ul li {
    margin-bottom: 7px;
}

footer ul li a,
footer ul li span {
    font-size: 30px;
    line-height: 25px;
}

.footer-widget .afi-image a {
    font-size: 30px;
    line-height: 25px;
}

.footer-widget .afi-image {
    padding-top: 10px;
}

.footer-widget.widget-2 img {
    padding-top: 0;
    width: 237px;
    height: auto;
}

.footer-widget.widget-1 p {
    font-size: 26px;
}

footer ul li .info-mail {
    text-transform: inherit;
}


/*********Profile page********/

section.profile-page {
    font-family: "Bebas Neue Pro", sans-serif;
}

section.profile-page .profile-header .profile-user {
    display: flex;
    align-items: center;
}

section.profile-page .profile-header .profile-user .profile-icon img {
    width: 60px;
    height: 60px;
}

section.profile-page .profile-header .profile-user .profile-icon {
    margin-right: 20px;
}

section.profile-page .profile-header .profile-user .profile-discription h2,
section.profile-page .profile-header .profile-user .profile-discription p,
.profile-course .profile-course-heading h2 {
    color: #000;
    letter-spacing: 0;
    text-transform: capitalize;
    margin-bottom: 0;
    font-weight: 400;
}

section.profile-page .profile-header .profile-user .profile-discription p {
    font-family: 'Myriad Pro Regular';
}

section.profile-page .profile-header .profile-user .profile-discription p span:first-child {
    color: #c42625;
}

section.profile-page .profile-header .edit-profile {
    text-align: right;
}

section.profile-page .profile-header .edit-profile .editprofile-btn {
    background: #94908d !important;
    border: 1px solid #94908d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

section.profile-page .profile-header .edit-profile .editprofile-btn:hover,
.profile-course-list .profile-course-detail .profile-course-body .resume-btn:hover,
.profile-course-list .profile-cours-showall .showall-btn:hover,
.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons .giveaway-btn:hover {
    background: #000 !important;
    border-color: #000;
    color: #fff;
}

section.profile-page .profile-header .edit-profile .editprofile-btn:hover span:first-child {
    color: #fff;
}

section.profile-page .profile-header .edit-profile .editprofile-btn span:first-child {
    color: #000;
    margin-right: 10px;
}

section.profile-page .profile-header .edit-profile {
    display: flex;
    justify-content: flex-end;
}

section.profile-page .profile-header {
    margin-bottom: 30px;
    margin-top: 30px;
}

.profile-course .profile-course-heading p {
    font-family: 'Myriad Pro Regular';
    color: #94908d;
}

.profile-page .profile-course.profile-course-mynote .row {
    max-width: 1150px;
    margin: 0 auto;
}

section.profile-page .profile-course.profile-course-yetstarted .profile-course-list.profile-course-note .row {
    max-width: 1150px;
    margin: 0 auto;
}

.profile-course-list .row {
    margin: 0 -15px;
    justify-content: center;
}

.profile-course-list .profile-course-column {
    padding: 0 30px;
    margin-bottom: 25px !important;
}

.profile-course-list .profile-course-detail {
    border: 5px solid #000;
    border-radius: 15px;
}

.profile-course-list .profile-course-detail .profile-course-title {
    display: flex;
    padding: 15px 5px 20px;
}

.profile-course-list .profile-course-detail .profile-course-body img {
    width: 100%;
}

.profile-course-list .profile-course-detail .profile-course-title .profile-course-icon {
    margin-right: 15px;
}

.profile-course-list .profile-course-detail .profile-course-title .profile-course-icon img {
    width: 70px;
}

.profile-course-list .profile-course-detail .profile-course-title .profile-course-discription h5,
.profile-course-list .profile-course-detail .profile-course-title .profile-course-discription p {
    color: #000;
    margin: 0;
}

.profile-course-list .profile-course-detail .profile-course-title .profile-course-discription p {
    margin: 0;
}

.profile-course-list .profile-course-detail .profile-course-title .profile-course-discription h5 span,
.profile-course-list .profile-course-detail .profile-course-title .profile-course-discription p span {
    color: #c42625;
}

.profile-course-list .profile-course-detail .profile-course-body {
    position: relative;
}

.profile-course-list .profile-course-detail .profile-course-body .resume-btn {
    position: absolute;
    right: 25px;
    bottom: 30px;
    height: 42px;
    width: 135px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border-radius: 10px;
    background: #c41425 !important;
    border: 1px solid #c41425;
    font-weight: 400;
}

.profile-course-list .profile-course-detail .profile-course-body .resume-btn span:first-child {
    margin-right: 10px;
    font-size: 22px;
}

.profile-course-list .profile-course-detail .profile-course-body .progress {
    border-radius: 0;
    height: 10px;
}

.profile-course-list .profile-course-detail .profile-course-foot {
    padding: 20px;
}

.profile-course-list .profile-course-detail .profile-course-foot p {
    margin: 0;
    color: #000;
}

.profile-course-list .profile-cours-showall {
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

.profile-course-list .profile-cours-showall .showall-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background: #c41425 !important;
    border: 1px solid #c41425;
    font-weight: 400;
}

section.profile-page .profile-course {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 7px solid #94908d;
}

section.profile-page .profile-header .profile-user .profile-discription h2 {
    margin-left: 20px;
    margin-bottom: 10px;
}

.profile-course-list .profile-course-detail .profile-notes {
    padding: 20px 15px;
    display: flex;
    align-items: center;
}

.profile-course-list .profile-course-detail .profile-notes .profile-notes-title h3 {
    margin: 0;
    letter-spacing: 0;
    color: #000;
    text-transform: capitalize;
    font-weight: 600;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
}

.profile-course-list .profile-course-detail .profile-notes .profile-notes-detail img {
    width: 100%;
    max-width: 100px;
    text-align: center;
}

.profile-course-list .profile-course-detail .profile-notes .profile-notes-detail {
    width: 45%;
}

.profile-course-list .profile-course-detail .profile-notes .profile-notes-title {
    width: 55%;
    padding-right: 10px;
}

.profile-course-list .profile-course-detail .profile-notes .profile-notes-detail .view-note-btn {
    height: 30px;
    width: 108px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 7px;
    background: #c41425 !important;
    border: 1px solid #c41425;
    font-weight: 400;
    padding: 0;
    margin: 10px auto 0;
}

.profile-course-list.profile-course-note .profile-course-column {
    padding: 0 15px;
}

.profile-course-list.profile-course-note row {
    margin: 0 -15px;
}

.profile-course-list.profile-course-note .profile-course-column .profile-course-started a {
    display: block;
}

.profile-course-list.profile-course-note .profile-course-column .profile-course-started img {
    width: 80%;
    height: auto;
}

.profile-course-list.profile-course-note .profile-course-column .profile-course-started .course-started-content {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #c414259e;
    text-align: center;
    padding: 18px 5px;
}

.profile-course-list.profile-course-note .profile-course-column .profile-course-started .course-started-content h2 {
    color: #fff;
    font-size: 40px;
    margin: 0;
    line-height: 40px;
    text-transform: capitalize;
    letter-spacing: 0;
    font-weight: 400;
}

.profile-course-list.profile-course-note .profile-course-column .profile-course-started {
    position: relative;
}

.profile-page .profile-course.profile-course-mynote {
    padding: 20px 0 60px;
}

section.profile-page .profile-course.profile-course-yetstarted {
    border: 0;
    padding: 30px 0 20px;
}

section.profile-page .profile-course .profile-course-heading {
    margin-bottom: 30px;
}


/*********Profile Edit Modal********/

.edit-profile-modal .modal-dialog {
    max-width: 650px;
    border: 5px solid #000;
    border-radius: 10px;
}

.edit-profile-modal .modal-dialog .modal-header {
    border: 0;
    padding: 20px;
}

.edit-profile-modal .modal-dialog .modal-header .modal-title h3 {
    letter-spacing: 0;
    text-transform: capitalize;
    color: #000;
    font-family: 'Myriad Pro Regular';
    margin: 0 0 5px;
    font-weight: 600;
}

.edit-profile-modal .modal-dialog .modal-header .modal-title p {
    font-family: 'Myriad Pro Regular';
    color: #000;
    margin: 0;
}

.edit-profile-picture .profile-details .profile-discription h2,
.edit-profile-picture .profile-details .profile-discription p {
    color: #000;
    font-size: 25px;
    margin: 0;
    line-height: 25px;
    text-transform: capitalize;
    font-family: 'Bebas Neue Pro';
}

.edit-profile-picture .profile-details .edit-profile {
    text-align: right;
}

.edit-profile-picture .profile-details .profile-discription p span:first-child {
    color: #c42625;
}

.edit-profile-picture .profile-details .profile-discription h2 {
    margin-left: 20px;
}

.edit-profile-picture .profile-details .edit-profile .editprofile-btn {
    background: #c42625 !important;
    border: 1px solid #c42625;
    font-family: 'Bebas Neue Pro';
    padding: 5px 14px;
    font-size: 22px;
}

.edit-profile-picture .profile-details .edit-profile .editprofile-btn span:first-child {
    color: #000;
    margin-right: 8px;
}

.edit-profile-picture .profile-details .profile-user {
    display: flex;
    align-items: center;
}

.edit-profile-picture .profile-details .profile-user .profile-icon {
    width: 50px;
    margin-right: 15px;
}

.edit-profile-picture .profile-details .profile-user .profile-icon img {
    width: 100%;
}

.edit-profile-picture .profile-details {
    align-items: center;
    margin-bottom: 30px;
}

.edit-profile-modal .modal-body {
    padding: 10px 30px;
}

.edit-profile-modal .edit-profile-form label {
    font-family: 'Bebas Neue Pro';
    margin-bottom: 5px;
    color: #000;
}

.edit-profile-modal .modal-body .edit-profile-form {
    width: 70%;
}

.edit-profile-modal .modal-body .edit-profile-form input {
    border: 1px solid #000;
    box-shadow: none;
}

.edit-profile-modal .modal-footer .cancel-btn {
    background: #fff !important;
    color: #000;
    border: 0;
    padding: 0;
    font-family: 'Bebas Neue Pro';
    margin: 0;
    font-size: 35px;
    font-weight: 500;
    margin-right: 15px;
    line-height: 1;
    box-shadow: none;
}

.edit-profile-modal .modal-footer .save-btn {
    background: #c42625 !important;
    border: 1px solid #c42625;
    font-size: 22px;
    border-radius: 7px;
    font-family: 'Bebas Neue Pro';
    padding: 6px 7px;
    line-height: 1;
}

.edit-profile-modal .modal-footer {
    border: 0;
    padding: 10px 25px;
}

.edit-profile-modal .modal-dialog .modal-header .btn-close {
    color: #000;
    opacity: 1;
    font-weight: bold;
    font-size: 8px;
    position: absolute;
    right: 25px;
    top: 25px;
    font-family: 'Bebas Neue Pro';
    background-position: center;
    background-size: 4em;
    box-shadow: none;
}

.view-note-modal .modal-dialog .modal-content {
    border-radius: 25px 28px 0 0;
    overflow: hidden;
}

.view-note-modal .modal-dialog .modal-content .modal-body {
    padding: 0;
}

section.profile-page .profile-header .profile-details {
    align-items: center;
}


/*********FragranceInfo Page*********/

.fragrance-info-things {
    text-align: center;
    padding: 138px 0 60px;
    background: #c41425;
    margin: 90px 0 40px;
    position: relative;
}

.fragrance-info-things .container {
    max-width: 1100px;
}

.fragrance-info-things .info-things-title {
    position: absolute;
    top: -64px;
    left: 0;
    right: 0;
    max-width: 995px;
    margin: 0 auto;
    background: #fff;
    border: 5px solid #000;
    padding: 25px;
}

.fragrance-info-things .info-things-title h1 {
    font-weight: bold;
    margin: 0;
}

.fragrance-info-things .info-things-wrap .info-things-column .info-things-detail h3 {
    font-weight: bold;
    color: #fff;
    text-transform: capitalize;
}

.fragrance-info-things .info-things-wrap .info-things-column .info-things-detail h4 {
    font-weight: bold;
    color: #fff;
    text-transform: capitalize;
}

.fragrance-info-things .info-things-wrap .info-things-column .info-things-image {
    margin-bottom: 25px;
}

.fragrance-info-things .info-things-wrap .info-things-column {
    max-width: 300px;
    margin: 0 auto;
}

.fragrance-info-things .info-things-wrap .info-things-column .info-things-detail p {
    font-weight: 600;
    color: #fff;
}

.fragrance-info-things .info-things-wrap .info-things-column .info-things-image img {
    width: 100%;
    border: 2px solid white;
}

.fragrance-info-things .info-things-seemore {
    position: absolute;
    bottom: -23px;
    left: 0;
    right: 0;
}

.fragrance-info-things .info-things-seemore .seemore-btn {
    width: 120px;
    height: 46px;
    border-radius: 0;
    background: #fff !important;
    color: #c41425;
    text-transform: uppercase;
    font-weight: 600;
    font-family: 'Bebas Neue Pro';
    font-size: 22px;
    border: 1px solid #000;
}

.fragrance-info-things .info-things-seemore .seemore-btn:hover {
    background: #000 !important;
    color: #fff;
}

header .dropdown-menu a.fragrances-icon {
    padding: 0 1rem;
}

.fragrance-growform .grow-formlist ul {
    padding: 20px 25px;
    margin: 0;
}

.fragrance-growform .grow-formlist ul li {
    text-align: left;
    margin-bottom: 5px;
}

.fragrance-growform .grow-formlist ul li a {
    text-decoration: none;
    color: #000;
    font-weight: 600;
    text-transform: capitalize;
    font-family: "Bebas Neue Pro", sans-serif;
    font-size: 25px;
    line-height: 1.2;
}

.fragrance-growform .grow-formlist {
    max-width: 650px;
    width: 100%;
    position: absolute;
    border: 3px solid #000;
    border-radius: 10px;
    background: #c9c9c9d9;
    left: 90px;
}

.fragrance-growform .grow-formlist ul li:last-child {
    margin-bottom: 0;
}

.fragrance-available {
    padding: 30px 0;
}

.fragrance-available .fragrance-available-title h2 {
    text-transform: capitalize;
    color: #000;
    margin: 0;
}

.fragrance-available .fragrance-available-button {
    text-align: right;
}

.fragrance-available .fragrance-available-button .search-now-btn {
    border-radius: 0;
    background: #c41425 !important;
    border: 1px solid #c41425;
}

.fragrance-available .fragrance-available-button .search-now-btn:hover {
    background: #000 !important;
    border-color: #000 !important;
}

.fragrance-follow {
    padding: 40px 0;
}

.fragrance-follow .fragrance-follow-detail {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.fragrance-follow .fragrance-follow-detail .fragrance-follow-title {
    width: 30%;
}

.fragrance-follow .fragrance-follow-detail .fragrance-follow-title h2 {
    margin: 0;
    font-weight: bold;
    color: #000;
}

.fragrance-follow .fragrance-follow-detail ul {
    display: flex;
    padding: 0;
    margin: 0;
    width: 70%;
    justify-content: flex-end;
    flex-wrap: wrap;
}

.fragrance-follow .fragrance-follow-detail ul li {
    padding-left: 15px;
}

.fragrance-follow .fragrance-follow-detail ul li img {
    max-width: 70px;
}

.fragrance-info-check .info-check-wrap .info-check-column {
    background: #c41425;
    text-align: center;
    max-width: 310px;
    padding: 25px;
    border: 1px solid #000;
}

.fragrance-info-check .container {
    max-width: 1060px;
}

.fragrance-info-check .info-check-wrap .info-check-column .info-check-image img {
    max-width: 150px;
    height: 150px;
    object-fit: contain;
}

.fragrance-info-check .info-check-wrap .info-check-column .info-check-detail h3 {
    color: #fff;
    text-align: center;
    margin: 0 auto 10px;
    text-transform: capitalize;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fragrance-info-check .info-check-wrap .info-check-column .info-check-detail .check-out-btn {
    background: #fff !important;
    color: #c41425;
    border: 1px solid #fff;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    border-radius: 14px;
    font-size: 26px;
}

.fragrance-info-check .info-check-wrap .info-check-column .info-check-detail .check-out-btn:hover {
    background: #000 !important;
    color: #fff;
    border-color: #000;
}

.fragrance-info-check {
    padding: 40px 0;
}

.fragrance-giveaway {
    background: #c41425;
    padding: 50px 0;
}

.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-image img {
    width: 100%;
    height: auto;
}

.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail h2 {
    color: #fff;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.giveaway-slider-detail h3 {
    color: #fff;
    font-weight: 600;
}

.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: space-between;
}

.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail {
    max-width: 500px;
    text-align: left;
}

.fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons .giveaway-btn {
    width: 49%;
    background: #fff !important;
    color: #c41425;
    font-weight: 600;
    border: 1px solid #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fragrance-giveaway .fragrance-giveaway-slider .row {
    align-items: center;
}

.afi-diy-section {
    padding: 50px 0;
}

.afi-diy-section .afi-diy-detail {
    max-width: 500px;
    margin: 0 auto;
}

.afi-diy-section .afi-diy-detail h2 {
    color: #000;
    letter-spacing: 0.6px;
    font-weight: 600;
}

.afi-diy-section .afi-diy-detail .afi-diy-content {
    background: #c41425;
    padding: 30px 20px 100px 50px;
    position: relative;
}

.afi-diy-section .afi-diy-detail .afi-diy-content p {
    font-size: 80px;
    color: #fff;
    font-family: "Bebas Neue Pro", sans-serif;
    line-height: 1;
    font-weight: 600;
    margin: 0;
    text-align: right;
}

.afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
    position: absolute;
}

.afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
    position: absolute;
    left: -27px;
    bottom: -126px;
}

.fragrance-giveaway .slick-arrow {
    background: none !important;
    position: relative;
}

.fragrance-giveaway .slick-arrow {
    width: 20px !important;
    height: 40px;
    top: 50%;
    position: absolute;
    display: flex !important;
    align-items: center;
    justify-content: center;
    line-height: 40px;
}

.fragrance-giveaway .slick-arrow.slick-prev {
    left: -55px;
}

.fragrance-giveaway .slick-arrow.slick-next {
    right: 0;
}

.fragrance-giveaway .slick-arrow:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '\f105';
    font-size: 50px;
    color: #fff;
    font-family: 'FontAwesome';
    font-weight: bold;
}

.fragrance-giveaway .slick-arrow.slick-prev:after {
    content: '\f104';
}

.fragrance-available .row {
    align-items: center;
}


/******END******/

.fragrancecalculator-section .tabs-button-wrap {
    display: flex;
    flex-direction: row;
    background: white;
    justify-content: space-between;
    margin-bottom: 30px;
}

.fragrancecalculator-section .tabs-button-wrap .nav-link {
    font-size: 23px;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 0px;
    width: 24%;
    background: #fff;
    color: #000;
    border: 1px solid #33333361;
    border-radius: 8px;
    box-shadow: none !important;
}

.fragrancecalculator-section .tabs-button-wrap .nav-link.active,
.fragrancecalculator-section .tabs-button-wrap .nav-link:hover {
    background: #c42625;
    color: #fff;
}

.search-wrap form a {
    text-decoration: none;
}

.candlecalculator-sec .btn {
    padding: 5px 20px;
    font-size: 20px;
}

.candlecalculator-sec .btn.btn-dark {
    background: #000;
}

.candlecalculator-sec .btn.btn-dark:hover {
    background: #c42625;
    border-color: #c42625;
}

.fragrance-calculator-fields {
    margin-bottom: 20px;
}

.fragrance-calculator-fields .form-group label {
    margin-bottom: 6px;
}

.calc-section .calc-container .form-control,
.calc-section .calc-container select,
.calc-section .calc-container .css-1s2u09g-control {
    border-radius: 5px;
    box-shadow: none;
    color: #000;
    font-size: 20px;
    min-height: 44px;
}

.calc-section .calc-container .form-control::placeholder {
    color: #757373;
}

.calc-section .calc-container .form-control:focus,
.calc-section .calc-container select:focus,
.calc-section .calc-container .css-1s2u09g-control:focus {
    box-shadow: 0 0 0 0.2rem #c4262561;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus,
.form-control:focus,
.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0.2rem #c4262561 !important;
}

.login-page h4 {
    margin: 0;
}

.login-page label {
    font-size: 20px;
    margin-bottom: 5px !important;
}

.login-page .form-control {
    border: 1px solid #000;
    font-size: 20px;
    color: #000;
    box-shadow: none;
}

.card-body-img {
    text-align: center;
    height: 120px;
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4px;
}

.card-body-img img {
    height: 100%;
    width: 100%;
}

@media (max-width: 767px) {
    .card-body-img {
        height: 100px;
        width: 100px;
    }
}


/* .login-page .form-control::placeholder{
    color: #000;
} */

.login-page small {
    font-size: 17px;
}

.login-page .login-button .btn {
    background: #c42625;
    color: #fff;
    max-width: 200px;
    margin: 0 auto;
}

.login-page .login-button .btn:hover {
    background: #000;
}

.login-page .login-button {
    text-align: center;
    margin-top: 20px;
}

.login-page {
    margin-bottom: 40px;
}

.course-main-video .play-btn {
    margin: 0;
    position: absolute;
    font-size: 60px;
    color: red;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.calc-section .calc-container .calculater-table tr td a {
    color: #dc3545;
}

.calc-section .calc-container .calculater-table tr td a:hover {
    text-decoration: underline !important;
}

.ingredints-from .row .col-md-2 label {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 500;
}

.ingredints-from .row .col-md-2 {
    flex: inherit;
    width: 25%;
    margin-bottom: 10px;
}

input[type="password" i].form-control {
    font-family: 'FontAwesome';
}

input[type="password" i].form-control::placeholder {
    font-family: 'Bebas Neue Pro';
}

.calc-section .calc-container .calculater-table {
    font-family: 'Myriad Pro Regular';
    font-size: 16px;
}

.candlecalculator-sec .text-danger.text-left {
    font-size: 14px;
    font-family: 'Myriad Pro Regular';
}

.sidebar-mini.admin-dashboard {
    font-family: 'Myriad Pro Regular';
    font-size: 16px;
}

.sidebar-mini.admin-dashboard .dasgboard-table.table-responsive .table {
    min-width: 1000px;
}

.sidebar-mini.admin-dashboard .dasgboard-table.table-responsive .table thead th {
    white-space: pre;
}

.form-wrapper.create-course-from form .row label {
    margin-bottom: 8px !important;
}

.form-wrapper.create-course-from form .row {
    margin-bottom: 10px;
}

.courses-section .video-section iframe {
    height: 400px;
}

.candlecalculator-sec label {
    margin-bottom: 6px;
}

.input-group .input-group-append .btn {
    padding: 8px 15px;
}

.searchbar-field.input-group {
    flex-wrap: nowrap;
}

.mien-canvas-graph {
    position: relative;
    height: 400px;
}

.mien-canvas-graph p {
    margin: 0;
}

.backbutton-space .btn.backbutton {
    max-width: 100px;
}

@media (max-width: 1699px) {
    .logo-wrap img {
        max-width: 165px;
    }
    .bottom-footer-copyright {
        bottom: 10px;
    }
    .top-header {
        padding: 20px 5px;
    }
    .header-info h2 {
        font-size: 70px;
    }
    .search-wrap input[type=search] {
        font-size: 20px;
        height: 45px;
    }
    .search-wrap button {
        font-size: 20px;
        height: 45px;
    }
    .menu-wrap nav.navbar {
        padding: 5px 0 0;
    }
    header .navbar-light .navbar-nav .nav-link {
        font-size: 25px;
        padding: 5px 0px;
    }
    .fragrance-info-things .info-things-title h1 {
        font-size: 50px;
    }
    .fragrance-info-things .info-things-title {
        top: -45px;
        border: 4px solid #000;
        padding: 10px;
    }
    .fragrance-info-things {
        padding: 100px 0 60px;
    }
}

@media (max-width: 1599px) {
    header .navbar-light .navbar-nav .nav-link {
        font-size: 25px;
    }
    .search-wrap button img {
        max-width: 25px;
    }
    .top-header {
        padding: 15px 5px;
    }
    header {
        margin-bottom: 0;
    }
    .menu-wrap nav.navbar {
        padding: 0;
    }
    .course-section {
        padding: 0 20px 0;
    }
    .category-info ul li {
        font-size: 19px;
    }
    .category-info a {
        padding: 2px 5px;
        font-size: 20px;
        max-width: 285px;
        margin: 10px 0 0;
    }
    .category-info h2 {
        margin: 0 0 10px;
    }
    .category-info img {
        max-width: 170px;
    }
    .category-info {
        padding: 20px 0px 20px;
    }
    .course-item .thumbnail img {
        max-width: 150px;
        margin: auto;
        border-radius: 20px;
    }
    .course-item .thumbnail {
        text-align: center;
    }
    .course-item p {
        font-size: 17px;
        padding: 10px 30px 0 0;
    }
    .fragrance-info h3 {
        font-size: 24px;
        max-width: 680px;
        margin: 10px auto;
    }
    .cst-row.d-flex {
        justify-content: center;
    }
    .main-resources .course-item .thumbnail img {
        max-width: 200px;
    }
    .edit-profile-modal .modal-dialog .modal-header {
        padding: 20px 20px 10px;
    }
}

@media (max-width: 1439px) {
    .course-detail-sidebar .course-detail-tabs .video-section iframe {
        height: 355px;
    }
}

@media (max-width: 1400px) {
    .courseCategories-wrap .slick-next {
        right: -13px;
    }
    .courseCategories-wrap .slick-slider .slick-list {
        width: calc(100% - 35px);
    }
}

@media (max-width: 1399px) {
    body h2 {
        font-size: 38px;
    }
    .course-detail-tabs .course-video-list {
        max-height: 350px;
    }
    .menu-wrap nav.navbar {
        padding: 0;
    }
    .course-item .thumbnail h4 {
        font-size: 19px;
    }
    header .navbar-light .navbar-nav .nav-link {
        font-size: 22px;
        padding: 6px 0px;
    }
    .header-info h2 {
        font-size: 60px;
        line-height: 63px;
    }
    .logo-wrap img {
        max-width: 140px;
    }
    .bottom-footer h2 {
        margin-bottom: 8px !important;
    }
    body footer h2 {
        font-size: 37px;
        line-height: 40px;
    }
    footer ul li a,
    footer ul li span {
        font-size: 25px;
        line-height: 23px;
    }
    footer ul li {
        font-size: inherit;
    }
    .footer-widget .afi-image {
        padding-top: 10px;
    }
    .footer-widget .afi-image a {
        font-size: 25px;
        line-height: 25px;
    }
    .footer-widget.widget-1 p {
        font-size: 22px;
        line-height: 22px;
    }
    footer .bottom-footer {
        margin-top: 123px;
    }
    footer {
        background: #94908d;
        padding: 25px 0 30px;
    }
    .social-wrap .footer-widget.widget-3 a {
        width: 45px;
        height: 45px;
        line-height: 45px;
        font-size: 25px;
    }
    section.profile-page .profile-header {
        margin-top: 30px;
    }
    section.profile-page .profile-header .profile-user .profile-icon {
        margin-right: 20px;
    }
    section.profile-page .profile-header .profile-user .profile-discription h2,
    section.profile-page .profile-header .profile-user .profile-discription p,
    .profile-course .profile-course-heading h2 {
        margin-bottom: 8px;
    }
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-discription h4,
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-discription p {
        line-height: 1;
    }
    .profile-course-list .profile-course-detail .profile-course-foot p {
        line-height: 1.2;
        font-size: 20px;
    }
    .banner-wrap .banner-video video {
        height: 270px;
    }
    .banner-wrap .banner-info h2 {
        margin: 0 auto 15px;
    }
    .banner-wrap .banner-info {
        max-width: 360px;
        padding: 25px;
        box-shadow: -5px 10px 0px 5px #000;
    }
    .fragrance-info-things .info-things-title {
        max-width: 850px;
        padding: 20px;
    }
    .fragrance-info-things {
        padding: 100px 0 60px;
    }
    html body .btn.resetbht {
        font-size: 25px;
        padding: 6px 18px;
    }
    .fragrance-info-things .info-things-title h1 {
        font-size: 40px;
    }
}

@media (max-width: 1300px) {
    .sec-padding {
        padding: 40px 0px;
    }
    header .dropdown-menu a {
        text-shadow: none;
        font-size: 16px;
        padding: 1px 10px;
    }
    header .dropdown-menu a.fragrances-icon {
        padding: 0 20px;
    }
    body h1 {
        font-size: 55px;
    }
}

@media (max-width: 1199px) {
    body h2 {
        font-size: 34px;
    }
    body h3,
    .category-info h3 {
        line-height: 1;
        font-size: 30px;
    }
    a.btn-href {
        font-size: 20px;
        max-width: 200px;
    }
    .menu-wrap .navbar-nav .navbar-item>a {
        font-size: 22px;
    }
    .dropdown-toggle.btn-primary {
        font-size: 22px;
    }
    .col-md-3 {
        flex: 0 0 33%;
        max-width: 33%;
    }
    .video-wrap {
        padding: 50px 0px;
    }
    .mt-50 {
        margin-top: 30px;
    }
    .sec-padding {
        padding: 30px 0px;
    }
    .course-info .course-des .course-detail {
        padding-top: 30px;
    }
    .lectures-list {
        padding-bottom: 50px;
    }
    .sec-padding.aboutThis-course {
        padding: 50px 0px 0px;
    }
    .might-like {
        padding-bottom: 50px;
    }
    .video-wrap.sec-padding {
        padding: 50px 0px 70px;
    }
    /* footer ul li {
    font-size: 28px; 
  } */
    html body .container,
    html body .container-sm {
        max-width: 100%;
    }
    .header-info {
        margin: 0;
    }
    .slick-slider.slick-initialized {
        max-width: 100%;
    }
    footer {
        padding: 25px 0px;
    }
    .courses-section .course-import .import-buttons ul li .btn span:first-child {
        font-size: 20px;
    }
    .courses-section .course-import .import-buttons ul li .btn {
        padding: 5px;
        width: 80px;
        font-size: 14px;
    }
    .course-detail-tabs .course-video-list .video-list-deials .video-section {
        margin-right: 10px;
    }
    .course-detail-tabs .course-video-list .video-discription h2 {
        font-size: 21px;
        line-height: 26px;
    }
    .other-course-video .video-discription h3 {
        font-size: 22px;
    }
    .course-main-video {
        width: 45%;
    }
    .search-wrap input[type=search] {
        padding: 3px 10px;
    }
    .search-wrap button,
    .search-wrap input[type=search] {
        font-size: 17px;
        height: 42px;
    }
    .header-info h2 {
        font-size: 55px;
        line-height: 58px;
    }
    .social-wrap .footer-widget.widget-3 a {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        margin: 0px 0% 0px 5px;
    }
    header .profile-user .dropdown-menu.show {
        left: inherit;
        right: 0;
    }
    .profile-course-list .profile-course-column {
        padding: 0 15px 15px;
        margin-bottom: 15px !important;
    }
    .profile-course-list .row {
        margin: 0 -15px;
    }
    section.profile-page .profile-header .profile-user .profile-icon {
        margin-right: 15px;
    }
    section.profile-page .profile-header .profile-user .profile-icon img {
        width: 50px;
        height: 50px;
    }
    section.profile-page .profile-header {
        margin-bottom: 40px;
    }
    .profile-course-list .profile-course-detail .profile-course-body .resume-btn {
        right: 15px;
    }
    .profile-course-list.profile-course-note .profile-course-column {
        margin-bottom: 20px;
    }
    section.profile-page .profile-header .profile-user .profile-discription h2 {
        margin-left: 25px;
    }
    .banner-wrap .banner-info {
        max-width: 330px;
        padding: 20px;
        box-shadow: -3px 5px 0px 5px #000;
    }
    body h1 {
        font-size: 50px;
    }
    html body {
        font-size: 22px;
    }
    body h4 {
        font-size: 27px;
    }
}

@media (max-width: 1034px) {
    .search-wrap button {
        padding: 4px 10px 6px 10px;
    }
    .pages-info p {
        font-size: 30px;
        letter-spacing: 1px;
    }
    .courseCategories-wrap .slick-next {
        right: 5px;
    }
    .courseCategories-wrap .item {
        padding: 0px 30px 0px 0px;
    }
}

@media (max-width: 1023px) {
    header .navbar-light .navbar-nav .nav-link {
        font-size: 20px;
        border: 2px solid #000;
    }
    .header-info h2 {
        font-size: 50px;
        line-height: 55px;
    }
    .logo-wrap img {
        max-width: 130px;
    }
    .course-detail-sidebar .course-detail-tabs .video-section iframe {
        height: 310px;
    }
    body h1 {
        font-size: 45px;
    }
    .profile-course-list .profile-course-detail .profile-course-foot p {
        font-size: 19px;
    }
}

@media (max-width: 991px) {
    body h2 {
        font-size: 30px;
    }
    body h3,
    .category-info h3 {
        font-size: 27px;
    }
    header {
        border-bottom: 2px solid #000;
        box-shadow: 0 0 20px #ddd;
    }
    .profile-user .dropdown-basic-head:after {
        color: #fff;
    }
    .header-info h2 {
        font-size: 44px;
        margin-bottom: 10px;
        line-height: 1.2;
    }
    .search-wrap {
        width: 100%;
    }
    .for-mobile .navbar .navbar-collapse {
        position: absolute;
        width: 100%;
        top: 51px;
    }
    .top-header {
        padding: 15px 10px;
    }
    .header-info h1 {
        padding-left: 75px;
    }
    .video-wrap.sec-padding li span {
        display: block;
        float: inherit;
        text-align: right;
    }
    .menu-wrap .navbar-nav .navbar-item {
        width: 100%;
        background: #c42625;
        margin: 0 auto;
        margin-right: 0px;
    }
    .navbar-light .navbar-toggler {
        border-width: 0px;
        box-shadow: none !important;
        display: block;
        width: 100%;
        max-width: 60px;
        background: #c42625;
        outline: 0;
        padding: 8px;
        margin: 0 0 0 auto;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .navbar-light .navbar-toggler {
        border-radius: 5px;
        right: 40px;
        position: absolute;
        top: -45px;
    }
    .courseCategories-wrap .owl-theme .owl-nav [class*=owl-] {
        font-size: 5em;
        line-height: 20px;
    }
    .courses-wrap.main-categories .course-item {
        text-align: center;
        margin-bottom: 25px;
    }
    .sec-padding {
        padding: 25px 0px;
    }
    .courses-wrap.main-categories.main-resources h2 {
        font-size: 22px;
    }
    .navbar.navbar-light {
        position: absolute;
        z-index: 111;
        width: 100%;
        right: 0;
        top: 94px;
        padding: 0 10px;
    }
    .pages-info p {
        font-size: 23px;
    }
    .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .about-course {
        padding-bottom: 25px;
    }
    .footer-widget.widget-1 p {
        font-size: 22px;
    }
    .search-wrap button {
        padding: 5px 15px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .search-wrap input[type=search] {
        font-size: 22px;
        padding: 5px 15px;
        height: 50px;
    }
    .search-wrap button img {
        max-width: 40px;
    }
    header .nav-item.dropdown,
    header .navbar-light .navbar-nav .nav-link {
        width: 100%;
    }
    header .navbar-light .navbar-nav .nav-link {
        font-size: 25px;
        padding: 6px 0px;
    }
    header .navbar-light .navbar-nav .navbar-toggler:focus {
        outline: 0;
        box-shadow: none;
    }
    header .navbar-light .navbar-nav .nav-link.active,
    header .navbar-light .navbar-nav .show>.nav-link,
    header .navbar-light .navbar-nav .nav-link:focus,
    header .navbar-light .navbar-nav .nav-link:hover {
        color: #050505;
        text-shadow: none;
    }
    header .navbar-nav .dropdown-menu {
        position: static;
        border-radius: 0;
        border: 0;
    }
    header .navbar-nav .dropdown-menu a.dropdown-item {
        font-size: 20px;
        text-align: center;
    }
    .course-item .thumbnail h4,
    .fragrance-info h3,
    .category-info ul li,
    .category-info a,
    .courseCategories-wrap .item h2 {
        font-size: 22px;
    }
    html body .container,
    html body .container-sm {
        max-width: 100%;
    }
    header .dropdown-menu a {
        text-align: left;
    }
    .navbar-light .navbar-toggler-icon {
        filter: invert(100%) sepia(0%) saturate(7495%) hue-rotate(15deg) brightness(104%) contrast(100%);
    }
    .navbar-toggler span.navbar-toggler-icon {
        background-image: url(/src/assets/img/Group-2-2.svg);
        filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(350%) contrast(106%);
        background-size: 15px;
    }
    .navbar-toggler.collapsed span.navbar-toggler-icon {
        background-image: url(/src/assets/img/download.svg);
        filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(248deg) brightness(550%) contrast(106%);
        background-size: 30px;
    }
    .fragrance-info-wrap {
        margin-top: 43px;
    }
    .container.auto-height .row {
        flex-wrap: wrap;
    }
    .navbar-collapse {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
    .footer-widget.widget-2 img {
        max-width: 160px;
    }
    nav.custom-pagination p {
        margin: 0 15px 0 0;
    }
    .course-detail-sidebar .course-detail-tabs .video-section iframe {
        height: 205px !important;
    }
    .courses-page .courses-section .course-detail-top .courses-leftbar .video-list-deials .video-section iframe {
        height: 100% !important;
    }
    .courses-section .course-import .import-detail,
    .courses-section .course-import .import-buttons {
        width: 100%;
    }
    .other-course-video .video-discription h3 {
        font-size: 19px;
    }
    .other-course-video .video-discription {
        margin-top: 5px;
    }
    .course-main-video {
        width: 50%;
    }
    .course-main-video .play-btn {
        font-size: 49px;
    }
    .thumbnail .fa-youtube-play {
        font-size: 35px;
    }
    body footer h2 {
        font-size: 30px;
        line-height: 35px;
    }
    .bottom-footer h2 {
        margin-bottom: 5px !important;
    }
    footer {
        padding: 20px 0px;
    }
    html body footer .top-inner {
        margin: 0 auto 15px;
        border-top: 2px solid white;
    }
    .profile-course-list.profile-course-note .profile-course-column .profile-course-started .course-started-content {
        padding: 12px 5px;
    }
    .profile-course-list.profile-course-note .profile-course-column .profile-course-started .course-started-content h2 {
        font-size: 30px;
        line-height: 35px;
    }
    body h1 {
        font-size: 40px;
    }
    html body {
        font-size: 20px;
    }
    body h4 {
        font-size: 25px;
    }
    .profile-course-list .profile-course-detail .profile-course-foot p {
        font-size: 15px;
    }
}

@media (max-width: 800px) {
    .category-top-box {
        margin-bottom: 20px;
    }
    .courseCategories-wrap .item h2 {
        font-size: 16px;
    }
}

@media (max-width: 767px) {
    body h1 {
        font-size: 35px;
        letter-spacing: 0.5px;
    }
    body h2 {
        font-size: 25px;
        margin-bottom: 15px;
    }
    .info-check-column .info-check-image {
        margin-bottom: 15px;
    }
    .fragrance-info-check .info-check-wrap .info-check-column .info-check-image img {
        max-width: 110px;
        height: 110px;
    }
    .thumbnail .fa-youtube-play {
        font-size: 45px;
    }
    .for-mobile .navbar .navbar-collapse {
        top: 9px;
    }
    .header-info h1 {
        padding-left: 60px;
    }
    .search-wrap button img {
        max-width: 30px;
    }
    .navbar-light .navbar-toggler {
        max-width: 50px;
    }
    .footer-widget.widget-2 img {
        max-width: 150px;
    }
    footer ul li a,
    footer ul li span {
        font-size: 19px;
        line-height: 22px;
    }
    .banner-wrap .banner-info {
        padding: 40px 12px;
    }
    .video-wrap.sec-padding {
        padding: 40px 0px 30px;
    }
    .sec-padding.aboutThis-course {
        padding: 40px 0px 0px;
    }
    .might-like {
        padding-bottom: 40px;
    }
    .img-right {
        text-align: center;
    }
    .footer-widget .afi-image a {
        font-size: 20px;
        line-height: 22px;
    }
    .footer-widget .afi-image {
        padding-top: 5px;
    }
    .footer-widget.widget-1 p {
        font-size: 16px;
    }
    .footer-widget.widget-2 img {
        max-width: 130px !important;
    }
    .about-course {
        padding-bottom: 15px;
    }
    .courses-wrap.main-categories h3 {
        font-size: 30px;
    }
    .video-wrap {
        padding: 40px 0px;
    }
    .courses-list {
        max-width: 100%;
        margin-left: auto;
        margin-top: 30px;
    }
    .lectures-list h2 {
        font-size: 30px;
    }
    .video-wrap .video-text {
        margin-bottom: 30px;
    }
    .video-wrap.sec-padding h3 {
        margin-bottom: 20px;
    }
    .video-wrap .video-text {
        margin-bottom: 30px;
    }
    .mt-30 {
        margin-top: 20px;
    }
    .course-info {
        max-width: 100%;
    }
    .course-info .course-name {
        flex-basis: 100%;
    }
    .course-info .course-des {
        flex-basis: 100%;
    }
    .pages-info p {
        font-size: 17px;
    }
    .lectures-list .heading-wrap {
        text-align: left;
        margin: 0 0px 30px;
    }
    .social-wrap .footer-widget.widget-3 a {
        width: 32px;
        height: 32px;
        line-height: 32px;
        font-size: 17px;
    }
    .courses-wrap.main-categories h3 {
        text-align: center;
    }
    .courses-wrap.main-categories.main-resources {
        margin-bottom: 20px;
    }
    .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    footer h2 {
        margin: 0 0 15px;
        font-size: 40px;
        line-height: 40px;
    }
    body footer h2 {
        margin: 0;
    }
    .footer-widget.widget-3 ul li {
        margin-right: auto;
    }
    .category-info img {
        margin-top: 15px;
        max-width: 150px;
    }
    .category-info a {
        font-size: 25px;
    }
    .might-like .video {
        margin: 10px 0px;
    }
    .video-wrap.sec-padding .col-md-3 {
        flex: 0 0 100%;
    }
    .main-categories .course-item .thumbnail {
        margin-top: 10px;
    }
    .courseCategories-wrap .slick-slider .item {
        padding: 0px 10px 0px 0px;
    }
    .courseCategories-wrap .slick-next {
        right: 0px;
    }
    .banner-wrap {
        padding: 0 !important;
        margin-bottom: 0;
        border: 5px solid #000;
    }
    .main-categories .course-item .thumbnail img {
        max-width: 175px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .main-categories h3 {
        margin-bottom: 15px;
    }
    footer ul li {
        margin-bottom: 0 !important;
    }
    .bottom-footer h2 {
        left: 10%;
    }
    .category-info img.dot {
        margin-right: auto;
    }
    .fragrance-info-wrap {
        margin-top: 25px;
    }
    html body .candle-cal.offset-md-4 .cal_ {
        margin-left: 0px !important;
        text-align: center;
    }
    .container.course-info {
        justify-content: center;
    }
    .row .header-info {
        max-width: 380px;
        margin: 0px 0px 0px 0;
    }
    .navbar-light .navbar-toggler {
        top: -113px;
        right: 33px;
    }
    .navbar.navbar-light {
        top: 133px;
    }
    .category-info img.dot {
        max-width: 230px;
        height: 230px;
    }
    footer {
        padding: 20px 0px;
    }
    .flex-directions.pagination-wrap {
        margin: 0;
        text-align: center;
    }
    .flex-directions.pagination-wrap .right-custom-search {
        max-width: 500px;
        margin: 0 auto;
    }
    .flex-directions.pagination-wrap .right-custom-search .btn.btn-primary {
        display: block;
        width: 100%;
    }
    .flex-directions.pagination-wrap .custom-pagination {
        margin-top: 30px;
    }
    .fragrance-details .col-md-6 {
        padding: 0px;
    }
    .right-custom-search .form-inline .input-wrap {
        width: 75%;
    }
    .right-custom-search .form-inline .button-wrap {
        width: 25%;
    }
    .menu-wrap .navbar-expand-lg .navbar-nav .dropdown-menu {
        column-count: 2;
    }
    .other-course-video {
        padding: 25px 0;
    }
    .lesson-list .lesson-list-items .lesson-discriptions p {
        font-size: 18px;
        line-height: 22px;
    }
    .courses-section .course-import {
        margin-bottom: 25px;
    }
    .other-course-video .video-discription {
        margin-bottom: 15px;
    }
    .courses-page .courses-section .course-detail-top .courses-leftbar .video-section iframe {
        height: 300px !important;
    }
    .course-main-video {
        width: 80%;
    }
    body h4 {
        font-size: 22px;
    }
}

.rawtoggle {
    display: none !important;
}

@media (max-width: 575px) {
    .rawtoggle {
        display: block !important;
        margin-top: 17px;
        background-color: white !important;
        color: #c42625 !important;
        font-weight: 600 !important;
        font-size: 21px !important;
        padding: 0px 10px !important;
    }
    .backbuttontop {
        position: absolute;
        top: 37px;
        right: 0;
    }
    .backbuttontop .btn {
        margin-right: 0px !important;
    }
    .backbuttontop-cards {
        width: 100% !important;
    }
    .rawpoints {
        display: none !important;
    }
    .course-main-video {
        width: 100%;
    }
    .courseCategories-wrap .owl-theme .owl-nav [class*=owl-] {
        display: none;
    }
    .bottom-footer .footer-widget.widget-3 {
        justify-content: center;
    }
    .might-like h3 {
        text-align: center;
        margin-bottom: 30px;
    }
    .might-like .text-left {
        text-align: center;
    }
    .owl-carousel.owl-theme {
        width: 100%;
    }
    header {
        margin-bottom: 0px;
    }
    footer h2 {
        text-align: center;
    }
    .social-wrap .footer-widget.widget-3 a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
    }
    .category-info ul li {
        font-size: 20px;
    }
    .pages-info p {
        font-size: 20px;
    }
    .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .bottom-footer h2 {
        left: 0;
        bottom: 8px;
    }
    html body footer .top-inner {
        max-width: 100%;
        border-width: 5px;
    }
    footer ul li a,
    footer ul li span {
        display: block;
        text-align: center;
    }
    .course-item .thumbnail h4,
    .fragrance-info h3,
    .category-info ul li,
    .category-info a,
    .courseCategories-wrap .item h2,
    footer ul li a,
    footer ul li span {
        font-size: 20px;
    }
    .bottom-footer .footer-widget.widget-3 {
        text-align: center;
    }
    .row-reverse {
        flex-direction: column-reverse;
    }
    .courses-wrap .course-item {
        max-width: 300px;
        margin: 0 auto;
        text-align: center;
    }
    .main-categories h3 {
        text-align: center;
    }
    html body {
        margin: 0px auto;
    }
    .col-md-1.col-sm-1.col-xs-0.hide-mb {
        display: none;
    }
    .container.auto-height {
        height: auto !important;
    }
    .d-flex.radio-section {
        flex-wrap: wrap;
    }
    .d-flex.radio-section .card-body {
        padding: 0px 1rem 0px;
    }
    .d-flex.radio-section {
        flex-wrap: wrap;
    }
    .hide-mb-space {
        display: none;
    }
    .candle-cal {
        padding: 50px 10px 10px 10px;
    }
    .tabs-button-wrap {
        flex-wrap: wrap;
        flex-direction: inherit;
    }
    .tabs-button-wrap a {
        width: 49%;
        margin: 5px 0px;
    }
    .category-info img.dot {
        max-width: 200px;
        height: 200px;
    }
    .search-wrap input[type=search] {
        font-size: 20px;
    }
    .fragrance-info-wrap {
        margin-top: 20px;
    }
    footer ul li {
        margin-bottom: 10px;
    }
    .footer-widget.widget-2 {
        margin: 25px 0 20px;
    }
    .footer-right-column .footer-widget.widget-3 {
        margin-bottom: 20px;
    }
    .social-wrap .align-items-end .col-md-4 {
        order: 1;
        padding-top: 10px;
    }
    .social-wrap {
        margin-top: 0px;
        margin-bottom: 0;
    }
    .bottom-footer .social-wrap .footer-widget.widget-1 {
        margin-bottom: 0;
        margin-top: 0;
    }
    .bottom-footer .footer-widget.widget-1 {
        text-align: center;
    }
    .pages-info {
        padding: 10px;
    }
    .header-info {
        max-width: 100% !important;
        margin: 15px 0px 10px 0 !important;
    }
    .header-info h2 {
        font-size: 34px;
    }
    .bottom-footer h2 {
        left: 0;
        text-align: center;
        padding-top: 0;
    }
    .course-detail-sidebar .course-profile-section .profile-image {
        margin-right: 15px;
        margin: 0 auto 20px;
    }
    .course-detail-sidebar .profile-detail {
        width: 100%;
        text-align: center;
    }
    .course-script-section {
        padding: 20px 15px;
        margin: 0px 0 40px;
    }
    .course-script-section .course-script-heading h4 {
        font-size: 25px;
    }
    footer .footer-right-column .footer-widget.widget-3 h2 {
        text-align: center;
    }
}

@media (max-width: 480px) {
    .thumbnail .fa-youtube-play {
        font-size: 35px;
    }
    .navbar-light .navbar-toggler {
        right: 20px;
        top: -99px;
    }
    .menu-wrap .navbar-expand-lg .navbar-nav .dropdown-menu {
        column-count: auto;
        max-height: 200px;
        overflow-y: auto;
    }
    .header-info h1 {
        padding-left: 40px;
    }
    .courses-page .courses-section .course-detail-top .courses-leftbar .video-section iframe {
        height: 190px !important;
    }
    .navbar-light .navbar-toggler {
        max-width: 45px;
        padding: 5px;
    }
    .navbar.navbar-light {
        top: 40px;
    }
    .search-wrap input[type=search] {
        font-size: 18px;
        height: 40px;
    }
    .search-wrap button {
        padding: 5px 10px;
        height: 40px;
    }
    .search-wrap button img {
        max-width: 25px;
    }
    .footer-widget.widget-2 img {
        max-width: 120px;
    }
    .banner-wrap .banner-info h3 {
        padding: 8px 0px;
    }
    body h3,
    .category-info h3 {
        line-height: 24px;
        font-size: 22px;
    }
}

@media (max-width: 374px) {
    .course-detail-tabs .course-video-list .video-list-deials .video-discription {
        width: 100%;
        text-align: center;
    }
    .course-detail-tabs .course-video-list .video-list-deials .video-section {
        margin-right: 0;
        margin: 0 auto 7px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
header .dropdown-menu a,
.footer-widget>.footer-widget>h2,
.footer-widget>p,
.footer-widget>a,
header .nav-item.dropdown a.nav-link,
header .navbar-light .navbar-nav .nav-link {
    font-family: "Bebas Neue Pro", sans-serif;
}

 ::placeholder {
    color: #000;
    opacity: 1;
    /* Firefox */
}

 :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #000;
}

 ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #000;
}


/********Buttons Styling*******/

.eidt-delete-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 270px;
    margin: 0 auto;
}

.eidt-delete-buttons .green-btn .btn {
    color: #fff;
    background: #0d6efd;
    font-size: 14px;
    box-shadow: none;
    /* padding: 8px 12px; */
}

.eidt-delete-buttons .yellow-btn .btn.btn-warning {
    font-size: 14px;
    box-shadow: none;
    padding: 0.25rem 0.5rem;
}

.userprofile-section {
    margin: 0 auto;
}


/******Course Page******/

.course-section {
    padding: 10px 20px 0;
}

.course-section .container {
    max-width: 1420px;
}

.course-section .course-title {
    text-align: center;
    margin-right: 60px;
}

.course-section .course-title h1 {
    letter-spacing: 0;
    font-size: 100px;
    line-height: 1;
    display: inline-block;
    margin: 0;
    padding: 20px 80px;
    border: 4px solid #000;
}

.course-section .course-details .course-boxes {
    border: 4px solid #000;
}

.course-section .course-details .course-boxes .course-boxes-title {
    text-align: center;
    padding: 15px;
    background: #c42625;
}

.course-section .course-row {
    margin: 0 -30px 0;
    padding-top: 50px;
    border-top: 7px solid #dfdddd;
    justify-content: center;
}

.course-section .course-row:first-child {
    padding-top: 0;
    border-top: 0;
}

.course-section .course-main-video iframe {
    height: 230px;
}

.course-section .course-row .course-column {
    padding: 0 30px 30px;
    margin-bottom: 30px;
    border-bottom: 5px solid #ddd;
}

.course-section .course-boxes .course-boxes-title h2 {
    color: #fff;
    font-weight: 500;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
}

.course-section .course-details .course-boxes .course-boxes-content {
    padding: 15px 20px 0;
}

.course-section .course-details .course-boxes .course-boxes-content p {
    font-size: 30px;
    line-height: 1.2;
    font-weight: 400;
    color: #000;
    max-width: 100%;
    font-family: "Bebas Neue Pro", sans-serif;
    position: relative;
    padding-bottom: 10px;
}

.show-detail {
    background-color: #c42625;
    color: #fff;
    font-size: 14px;
    width: 25px;
    display: inline-block;
    text-align: center;
    height: 25px;
    line-height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 10px;
}

.course-section .course-details .course-boxes .course-boxes-content p input[type="checkbox"] {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    right: 0;
    bottom: 10px;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
}

.course-section .course-details .course-boxes .course-boxes-content p input[type="checkbox"]:checked+span {
    display: inline-block;
}

.course-section .course-details .course-boxes .course-boxes-content span {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.course-section .course-boxes-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -25px;
    margin-bottom: 5px;
}

.course-section .course-boxes-button .start-button .start-button-text {
    border: 4px solid #000;
    border-radius: 50px;
    background: #94908d;
    color: #fff;
    height: 72px;
    display: flex;
    width: 260px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 27px;
    font-weight: 500;
    text-transform: capitalize;
}

.course-section .course-boxes-button .start-button .start-button-text:hover {
    background: #c42625;
}

.course-section .course-boxes-button .start-button {
    position: relative;
    left: -35px;
}

.course-section .course-boxes-button .course-box-icon {
    position: relative;
    left: -14px;
    min-height: 120px;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: center;
}

.course-section .course-boxes-button .course-box-icon img {
    width: 120px;
    height: 120px;
    object-fit: contain;
}

.course-section .course-head-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
}

.course-section .fragrance-course-video {
    width: 30%;
}

.course-section .fragrance-course-video .fragrance-video iframe {
    width: 100%;
    height: 245px;
}

.welcome-fragrance {
    background: #c42625;
    border: 8px solid #000;
    margin-bottom: 6px;
    text-align: center;
    padding: 25px 20px;
}

.course-section .welcome-fragrance p {
    margin: 0;
    color: #fff;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
}

.course-section .fragrance-course-video .fragrance-video iframe {
    width: 100%;
    height: 230px;
    vertical-align: top;
    border-radius: inherit;
}

.course-section .fragrance-video {
    width: 96%;
    margin: 0 auto;
}

.afi-diy-video {
    background: #202020;
}

.afi-diy-video .afi-diy-title {
    padding: 20px 20px 10px;
}

.afi-diy-video .afi-diy-title h3 {
    color: #fff;
}

.afi-diy-video .afi-diy-title p {
    margin: 0;
    color: #fff;
}

.afi-diy-video .afi-diy-icon {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 15px;
}

.afi-diy-video .afi-diy-icon .loading-icons span {
    display: inline-block;
    margin-right: 10px;
}

.afi-diy-video .course-video-list .video-list-deials {
    display: flex;
}

.afi-diy-video .course-video-list .video-list-deials .video-discription p {
    color: #b7b7b7;
    font-size: 21px;
}

.afi-diy-video .course-video-list .video-list-deials .video-discription h3 {
    color: #fff;
    margin: 0 0 5px;
    text-transform: capitalize;
}

.afi-diy-video .course-video-list .video-list-deials .video-discription {
    width: 68%;
}

.afi-diy-video .course-video-list .video-list-deials .video-section img {
    width: 100%;
}

.afi-diy-video .course-video-list .video-list-deials .video-section {
    width: 30%;
    margin-right: 15px;
}

.afi-diy-video .course-video-list {
    padding: 0 20px;
    overflow-y: auto;
    max-height: 550px;
}

.afi-diy-video .course-video-list .video-list-deials {
    display: flex;
    margin-bottom: 20px;
}

.calculater-convertor .calculater-column {
    margin: 0 auto;
}

.calculater-convertor .calculater-column form .col-md-12 {
    margin-bottom: 15px;
}

.calculater-convertor .calculater-column form .col-md-12 label {
    margin-bottom: 5px;
}

.calc-section {
    font-size: 20px;
}

.container-video {
    margin-bottom: 10px;
}

@media (max-width: 1599px) {
    .course-section .container {
        padding: 0 30px;
    }
    .course-section .course-details .course-boxes .course-boxes-content {
        padding: 10px 10px 0;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        margin-bottom: 0;
    }
    .course-section .course-boxes-button .course-box-icon img {
        width: 90px;
        height: 90px;
    }
    .course-section .course-boxes-button {
        margin-top: 0;
        margin-bottom: 0;
    }
    .course-section .course-boxes-button .course-box-icon {
        min-height: 90px;
    }
    .course-section .course-row .course-column {
        padding: 0 30px 15px;
        margin-bottom: 15px;
        border-bottom: 3px solid #ddd;
    }
    .course-section .course-main-video iframe {
        height: 230px;
    }
    .course-section .course-details .course-boxes .course-boxes-title {
        padding: 10px 15px;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        font-size: 24px;
    }
    .course-section {
        padding: 0 20px 0;
    }
    .course-section .course-title h1 {
        font-size: 40px;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        font-size: 23px;
        height: 55px;
    }
    .fragrance-giveaway-slider {
        padding: 0 10px;
    }
}

@media (max-width: 1399px) {
    .course-section .course-row {
        margin: 0 -15px 0;
        padding-top: 40px;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        font-size: 19px;
    }
    .course-section .course-boxes-button .start-button {
        left: -20px;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        font-size: 20px;
        width: 215px;
        height: 45px;
    }
    .course-section .course-boxes-button .course-box-icon {
        left: -11px;
    }
    .course-section .fragrance-course-video {
        width: 31%;
    }
    .course-section .welcome-fragrance p {
        font-size: 18px;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        padding-bottom: 0;
    }
    .course-section .course-row .course-column {
        padding: 0 15px 15px;
    }
    .course-section .course-row .course-column {
        padding: 0 15px 10px;
        margin-bottom: 10px;
        border-bottom: 2px solid #ddd;
    }
    .course-section .course-boxes-button .course-box-icon img {
        width: 80px;
        height: 80px;
    }
    .fragrance-follow .fragrance-follow-detail ul li img {
        max-width: 60px;
    }
    .fragrance-follow .fragrance-follow-detail ul li {
        padding-left: 10px;
    }
    .fragrance-giveaway .slick-arrow.slick-prev {
        left: -30px;
    }
    .fragrance-growform .grow-formlist ul {
        padding: 10px 15px;
    }
    .ingredints-from .row .col-md-2 label {
        font-size: 18px;
    }
}

@media (max-width: 1199px) {
    body h5 {
        font-size: 22px;
    }
    .fragrance-available {
        padding: 30px 0 10px;
    }
    .fragrance-follow {
        padding: 40px 0 10px;
    }
    .afi-diy-section {
        padding: 30px 0;
    }
    header .nav-item.dropdown {
        margin-right: 15px;
    }
    header .navbar-light .navbar-nav .nav-link {
        margin-right: 15px;
    }
    .course-section {
        padding: 10px 0 0;
    }
    .welcome-fragrance {
        border: 5px solid #000;
        padding: 18px 10px;
    }
    .course-section .welcome-fragrance p {
        font-size: 16px;
    }
    .course-section .course-title h1 {
        font-size: 30px;
    }
    .course-section .course-title {
        margin-right: 30px;
    }
    .course-section .fragrance-course-video .fragrance-video iframe {
        height: 180px;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        font-size: 16px;
        margin: 0;
    }
    .course-section .course-boxes-button {
        margin-top: 0;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        font-size: 15px;
        width: 150px;
        border: 4px solid #000;
        height: auto;
        padding: 7px 0;
    }
    .course-section .course-boxes-button .course-box-icon {
        min-height: 80px;
    }
    .course-section .course-boxes-button .course-box-icon img {
        width: 80px;
        height: 80px;
    }
    .courseCategories-wrap .item h2 {
        font-size: 18px
    }
    .courseCategories-wrap .slick-slider .slick-list {
        width: calc(100% - 80px);
        margin: auto;
        padding-left: 10px;
    }
    button.slick-arrow.slick-prev {
        left: 13px;
    }
    .course-item .thumbnail h4 {
        font-size: 16px;
        line-height: 18px;
    }
    .course-section .course-main-video iframe {
        height: 255px;
    }
    .course-section .course-details .course-boxes {
        height: 100%;
    }
    .fragrance-info-things .info-things-title {
        max-width: 700px;
        padding: 10px;
        top: -35px;
    }
    .fragrance-info-things .info-things-title h1 {
        font-size: 35px;
    }
    .fragrance-info-things {
        padding: 80px 0 60px;
    }
    .fragrance-follow .fragrance-follow-detail ul li img {
        max-width: 50px;
    }
    .fragrance-giveaway-slider {
        padding: 0 35px;
    }
    .fragrance-giveaway .slick-arrow.slick-prev {
        left: 0;
    }
    .fragrance-giveaway .slick-arrow:after {
        font-size: 40px;
    }
    .afi-diy-section .afi-diy-detail {
        max-width: 400px;
        margin: 0 auto;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content p {
        font-size: 60px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
        bottom: -126px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user img {
        max-width: 180px;
    }
    .afi-diy-video .course-video-list {
        max-height: 415px;
    }
    .banner-wrap .banner-info input {
        padding: 8px;
    }
    .courses-section .other-course-video button.slick-arrow.slick-next {
        right: -16px;
    }
    .courses-section .other-course-video button.slick-arrow {
        z-index: 9;
        width: 25px;
        height: 25px;
        top: 40%;
    }
    .courses-section .other-course-video button.slick-arrow.slick-prev {
        left: -5px;
    }
    .fragrance-growform .grow-formlist {
        left: 60px;
    }
    .input-group .input-group-append .btn {
        padding: 6px 12px;
    }
}

@media (max-width: 1023px) {
    .course-section .course-boxes-button .course-box-icon img {
        width: 90px;
        height: 90px;
    }
    .category-top-box {
        width: 22%;
    }
}

@media (max-width: 991px) {
    .fragrance-info-check .info-check-wrap .info-check-column .info-check-detail .check-out-btn {
        font-size: 23px;
    }
    .ingredints-from .row .col-md-2 label {
        font-size: 17px;
    }
    .profile-page .profile-course.profile-course-mynote {
        padding: 0 0 30px;
    }
    section.profile-page .profile-course.profile-course-yetstarted {
        padding: 0;
    }
    body h5 {
        font-size: 19px;
    }
    .course-main-video {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .course-main-video img {
        height: auto;
    }
    .course-section {
        padding: 20px 0 0;
    }
    .course-main-video .play-btn {
        margin: 0;
        font-size: 49px;
    }
    .sidebar-mini nav.main-header.navbar.navbar-expand.navbar-white.navbar-light {
        position: static !important;
    }
    header .nav-item.dropdown,
    header .navbar-light .navbar-nav .nav-link {
        margin-right: 0;
    }
    header .me-auto.navbar-nav {
        flex-wrap: wrap;
    }
    .main-sidebar.sidebar-dark-primary {
        margin-top: 38px;
    }
    .course-section .course-title {
        margin-right: 0;
        margin-bottom: 30px;
    }
    .course-section .course-head-bar {
        justify-content: center;
        margin-bottom: 30px;
    }
    .course-section .fragrance-course-video {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .course-section .fragrance-video {
        width: 48%;
        margin: 0;
    }
    .welcome-fragrance {
        width: 48%;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .course-section .course-boxes-button .course-box-icon {
        left: 0;
    }
    .course-section .course-boxes-button .start-button {
        left: 15px;
        margin-right: 10px;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        height: auto;
        width: 100%;
        border: 4px solid #000;
        padding: 11px 20px;
    }
    .course-section .course-row {
        margin: 0 -15px 0;
        padding-top: 35px;
    }
    .profile-user.dropdown {
        padding: 5px 0 !important;
        background: #c42625;
        text-align: center;
    }
    header .profile-user .dropdown-menu.show {
        background: #fff;
    }
    header .profile-user .dropdown-menu.show .dropdown-item {
        color: #c42625;
        font-size: 18px;
    }
    .profile-user .dropdown-basic-head .rounded-circle-front {
        width: 40px;
        height: 40px;
    }
    .banner-wrap .banner-info {
        max-width: 270px;
        padding: 15px;
    }
    .banner-wrap .banner-info h2 {
        margin: 0 auto 10px;
    }
    .grow-form button img {
        max-width: 20px;
    }
    .grow-form button {
        top: 7px;
        right: 2px;
    }
    .banner-wrap .banner-info input {
        padding: 9px 9px 5px;
        font-size: 16px;
        padding-right: 30px;
        border-radius: 8px;
    }
    .banner-wrap .banner-info input::placeholder {
        font-size: 20px;
    }
    .fragrance-info-things .info-things-title {
        max-width: 500px;
    }
    .fragrance-info-things {
        padding: 80px 0 60px;
    }
    .fragrance-info-things .info-things-wrap .info-things-column .info-things-image {
        margin-bottom: 12px;
    }
    .fragrance-follow .fragrance-follow-detail ul li img {
        max-width: 45px;
    }
    .fragrance-info-check .info-check-wrap .info-check-column .info-check-detail h3 {
        min-height: 100px;
    }
    .fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail h2 {
        margin-bottom: 5px;
    }
    .giveaway-slider-detail h3 {
        margin-bottom: 10px;
    }
    .fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons {
        margin-top: 20px;
    }
    .fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons .giveaway-btn {
        height: 50px;
        font-size: 25px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content p {
        font-size: 50px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user img {
        max-width: 150px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
        bottom: -85px;
        left: 0;
    }
    .afi-diy-video .course-video-list .video-list-deials .video-discription p {
        font-size: 17px;
        margin: 0;
    }
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-icon {
        margin-right: 10px;
    }
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-icon img {
        width: 50px;
    }
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-discription h4,
    .profile-course-list .profile-course-detail .profile-course-title .profile-course-discription p {
        font-size: 18px;
        color: #000;
    }
    .profile-course-list .profile-course-detail .profile-course-foot {
        padding: 10px;
    }
    .profile-course-list .profile-course-detail .profile-course-title {
        padding: 15px 5px;
    }
    .profile-course-list .profile-course-detail .profile-course-body .resume-btn span:first-child {
        margin-right: 7px;
        font-size: 18px;
    }
    .profile-course-list .profile-course-detail .profile-course-body .resume-btn {
        font-size: 18px;
        height: 34px;
        width: 100px;
    }
    .profile-course-list .profile-cours-showall {
        margin-top: 20px;
    }
    .profile-course-list .profile-course-detail .profile-notes {
        padding: 15px 10px;
    }
    .profile-course-list .profile-course-detail .profile-notes .profile-notes-detail .view-note-btn {
        height: 28px;
        width: 100%;
        max-width: 108px;
        font-size: 16px;
    }
    .profile-course-list .profile-cours-showall {
        margin-top: 0;
    }
    html body .btn.resetbht {
        font-size: 22px;
    }
    .courses-section .video-section {
        border: 5px solid #c21626;
    }
    .courses-section .video-section iframe {
        height: 240px;
    }
    .course-detail-tabs .course-video-list {
        max-height: 150px;
    }
    .fragrancecalculator-section .tabs-button-wrap .nav-link {
        font-size: 20px;
        padding: 6px 0px;
        margin-right: 0;
        margin: 0 5px;
    }
    .login-page {
        padding: 25px 15px;
    }
    .header-head .header-info {
        text-align: center;
        padding-right: 95px;
    }
    .header-head .head-searchbar {
        text-align: center;
        width: 100%;
        padding-right: 106px;
    }
    .header-head .head-searchbar .search-wrap {
        width: 65%;
        margin-right: auto;
    }
    .ingredints-from .row .col-md-2 {
        width: 33%;
    }
    .fragrance-growform .grow-formlist {
        left: 0;
    }
    .category-info .img-right iframe {
        height: auto;
    }
    .category-top-box {
        width: 30%;
        margin-bottom: 20px;
    }
    .mien-canvas-graph {
        height: 300px;
    }
    .backbutton-space .btn.backbutton span {
        display: none;
    }
    .backbutton-space .btn.backbutton {
        padding: 15px 0px;
        background-position: center !important;
        max-width: 50px;
        margin-right: 6px;
    }
    .custom-pagination.userlist-pagination p {
        margin-bottom: 5px;
    }
}

@media (max-width: 767.98px) {
    .fragrance-growform .grow-formlist ul {
        padding: 10px;
    }
    .grow-form button {
        top: 0px;
    }
    .banner-wrap .banner-info input {
        padding: 5px 9px;
        text-align: left;
    }
    .banner-wrap .banner-info input::placeholder {
        font-size: 16px;
        text-align: left;
    }
    .courses-page {
        padding: 30px 0 0;
    }
    .courses-section .video-section {
        border: 5px solid #c21626;
    }
    .fragrance-info-check {
        padding: 20px 0;
    }
    .course-main-video .play-btn {
        margin: 0;
        font-size: 40px;
    }
    .course-main-video {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sidebar-mini .main-sidebar,
    .sidebar-mini .main-sidebar::before {
        box-shadow: none !important;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar {
        margin-left: inherit !important;
    }
    .sidebar-mini.sidebar-collapse .main-sidebar .nav-link,
    .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar .nav-link,
    .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar .nav-link {
        width: calc(250px - 0.5rem * 2) !important;
    }
    .content-wrapper {
        padding: 30px 10px !important;
    }
    .fragrance-info-things .info-things-wrap .info-things-column {
        max-width: 275px;
        margin: 0 auto 30px;
    }
    .fragrance-info-things {
        padding: 80px 0 40px;
    }
    .fragrance-available-title {
        margin-bottom: 30px;
    }
    .fragrance-available {
        text-align: center;
    }
    .fragrance-available .fragrance-available-button {
        text-align: center;
    }
    .fragrance-follow {
        padding: 15px 0;
        text-align: center;
    }
    .fragrance-follow .fragrance-follow-detail .fragrance-follow-title {
        width: 100%;
        margin-bottom: 20px;
    }
    .fragrance-follow .fragrance-follow-detail ul {
        width: 100%;
        justify-content: center;
    }
    .fragrance-follow .fragrance-follow-detail ul li {
        padding: 0 5px 10px;
    }
    .fragrance-follow .fragrance-follow-detail ul li img {
        max-width: 35px;
    }
    .fragrance-info-check .info-check-wrap .info-check-column {
        margin: 0 auto 20px;
    }
    .fragrance-giveaway {
        padding: 30px 0;
    }
    .giveaway-slider-image {
        margin-bottom: 30px;
    }
    .afi-diy-section {
        padding: 0 0 20px;
    }
    .fragrance-info-check .info-check-wrap .info-check-column .info-check-detail h3 {
        min-height: auto;
        margin-bottom: 20px;
    }
    .fragrance-info-check .info-check-wrap .info-check-column {
        max-width: 260px;
        padding: 20px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content {
        padding: 30px 20px 30px 50px;
    }
    .afi-diy-section .afi-diy-detail {
        margin: 0 auto 55px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user img {
        max-width: 120px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
        bottom: -50px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content p {
        font-size: 40px;
    }
    .fragrance-giveaway .fragrance-giveaway-slider .giveaway-slider-detail .giveaway-slider-buttons .giveaway-btn {
        height: 45px;
        font-size: 21px;
    }
    .afi-diy-video .afi-diy-icon .loading-icons span img,
    .afi-diy-video .afi-diy-icon .add-icons img {
        max-width: 25px;
    }
    .other-course-video .video-section {
        max-width: 95%;
        margin: 0 auto;
    }
    .courses-section .other-course-video button.slick-arrow.slick-prev {
        left: 5px;
    }
    .courses-section .other-course-video button.slick-arrow.slick-next {
        right: 0;
    }
    .courses-wrap .course-item {
        margin-bottom: 25px;
    }
    .fragrance-u .fragrance-info {
        margin: 20px 0 0;
    }
    .after-bar:after {
        top: 10px;
    }
    .edit-profile-modal .modal-body .edit-profile-form {
        width: 100%;
    }
    .edit-profile-modal {
        padding: 0 10px !important;
    }
    .fragrance-calculator-fields .form-group {
        margin-bottom: 12px;
    }
    .fragrance-calculator-fields .form-group label {
        margin-bottom: 5px;
    }
    .fragrance-calculator-fields .ifra-preferred-fields .form-control {
        margin-bottom: 15px;
    }
    .fragrance-calculator-fields .check-fields {
        padding: 0 8px 10px !important;
    }
    .candlecalculator-sec .btn {
        padding: 3px 16px;
        font-size: 18px;
    }
    .header-head .header-info {
        padding-right: 0;
    }
    .header-head .head-searchbar .search-wrap {
        width: 100%;
        margin-right: 0;
    }
    .header-head .head-searchbar {
        padding-right: 10px;
    }
    .calc-section .calc-container .form-control,
    .calc-section .calc-container select,
    .calc-section .calc-container .css-1s2u09g-control {
        font-size: 18px;
        min-height: 40px;
    }
    .ingredints-from .row .col-md-2 {
        width: 50%;
    }
    .category-info .img-right iframe {
        height: 410px;
    }
    .category-top-box {
        width: 46%;
    }
    .profile-user .dropdown-basic-head .rounded-circle-front {
        width: 30px;
        height: 30px;
    }
    .profile-user .dropdown-basic-head:after {
        right: -20px;
        font-size: 20px;
    }
    .profile-user.dropdown {
        padding: 2px 0 !important;
        border: 2px solid #000;
    }
    .fragrance-info-things {
        margin: 80px 0 40px;
        padding: 60px 0 40px;
    }
    .fragrance-info-things .info-things-title h1 {
        font-size: 30px;
    }
    .edit-profile-picture .profile-details .edit-profile .editprofile-btn {
        padding: 4px 10px;
        font-size: 19px;
    }
    .edit-profile-picture .profile-details .profile-discription h2,
    .edit-profile-picture .profile-details .profile-discription p {
        font-size: 20px;
        line-height: 20px;
    }
    .edit-profile-picture .profile-details .profile-discription h2 {
        margin-left: 0;
    }
    .backbutton-space {
        padding-left: 0 !important;
    }
    .mien-canvas-graph {
        height: 250px;
    }
    .backbutton-space {
        /* order: -1;
        width: 100% !important; */
        margin-bottom: 10px;
        padding-left: 0 !important;
    }
    .admin-course-row .admin-course-column {
        margin-bottom: 15px;
    }
    .custom-pagination.userlist-pagination {
        margin-bottom: 10px;
    }
}

@media (max-width: 767px) {
    .course-section .course-title h1 {
        padding: 10px 32px 10px;
        border: 2px solid #000;
        font-size: 16px;
        margin: 10px 0 0;
    }
    .course-section .course-title {
        margin-bottom: 25px;
    }
    .course-section .fragrance-course-video .fragrance-video iframe {
        height: 150px;
    }
    .course-section .course-boxes-button .course-box-icon img {
        width: 70px;
        height: 70px;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        border: 2px solid #000;
        padding: 10px 10px;
        font-size: 16px;
    }
    .course-section .course-row {
        border-top: 4px solid #dfdddd;
    }
    .courseCategories-wrap .slick-slider .slick-list {
        width: calc(100% - 100px);
        padding-left: 5px;
    }
    .img-right iframe {
        margin: 35px 0 0;
    }
    .banner-wrap .banner-info {
        max-width: 243px;
        padding: 10px !important;
        box-shadow: none;
        border: 4px solid #000;
    }
    .banner-wrap .banner-description .container {
        padding: 0 15px;
    }
    .calculater-table .table-responsive .table {
        width: 700px;
    }
    .calc-section {
        font-size: 18px;
    }
    .calc-section .calc-container h2 {
        line-height: 1.4;
    }
    section.profile-page .profile-header {
        margin-bottom: 30px;
    }
    section.profile-page .profile-header .profile-user .profile-icon img {
        width: 45px;
        height: 45px;
    }
    section.profile-page .profile-header .profile-user .profile-icon {
        margin-right: 10px;
    }
    section.profile-page .profile-header .profile-user {
        margin-bottom: 20px;
    }
    .profile-page .profile-course.profile-course-mynote {
        padding: 20px 0 40px;
    }
    section.profile-page .profile-course {
        margin-bottom: 20px;
    }
    .edit-profile-modal .modal-footer .cancel-btn {
        font-size: 25px;
        margin-right: 10px;
    }
    .edit-profile-modal .modal-footer .save-btn {
        font-size: 18px;
    }
    .edit-profile-picture .profile-details {
        margin-bottom: 20px;
    }
    .edit-profile-modal .modal-body {
        padding: 10px 15px;
    }
    .edit-profile-modal .modal-dialog .modal-header .btn-close {
        font-size: 4px;
    }
    .edit-profile-modal .modal-dialog .modal-header .modal-title p {
        font-size: 18px;
    }
    .edit-profile-modal .edit-profile-form label {
        font-size: 16px;
    }
    .edit-profile-modal .edit-profile-form label small {
        font-size: 12px !important;
    }
}

@media (max-width: 660px) {
    .right-custom-search .form-inline {
        max-width: 100%;
    }
    .right-custom-search.custom-1200 {
        width: 100%;
    }
    .header-info h2 {
        font-size: 30px;
    }
    .navbar-light .navbar-toggler {
        padding: 3px 0;
        right: 30px;
        max-width: 40px !important;
        top: -103px;
    }
    .navbar.navbar-light {
        top: 120px;
    }
}

@media (max-width: 574.98px) {
    .edit-profile-picture .profile-details .profile-discription h2,
    .edit-profile-picture .profile-details .profile-discription p {
        font-size: 18px;
        line-height: 18px;
    }
    <<<<<<< HEAD=======.admin-course-column .mr-sm-2,
    .admin-course-column .mx-sm-2 {
        margin-right: 0.5rem!important;
    }
    .admin-course-column .button-wrap {
        margin-left: 0.5rem;
    }
    .admin-course-row .admin-course-column {
        padding: 0;
    }
    >>>>>>>Dev-release .course-section .course-boxes .course-boxes-title {
        padding: 15px 10px;
    }
    .course-section .course-main-video iframe {
        height: 220px;
    }
    .welcome-fragrance {
        width: 100%;
        margin: 0 0 15px;
    }
    .course-section .fragrance-video {
        width: 100%;
    }
    .course-section .container {
        padding: 0 15px;
    }
    .course-section .course-boxes-button .start-button .start-button-text {
        padding: 10px 30px;
    }
    .course-section .course-row {
        padding-top: 25px;
    }
    .course-section .course-details .course-boxes .course-boxes-content p {
        min-height: inherit;
    }
    .logo-wrap img {
        max-width: 70px;
    }
    .header-info h2 {
        text-align: center;
        margin: -65px 0 22px;
    }
    .search-wrap input[type=search] {
        font-size: 15px;
        height: 42px;
        width: 100%;
    }
    .search-wrap button {
        height: 42px;
    }
    .category-top-box {
        width: 45%;
    }
    .after-bar span {
        padding: 0 20px 0 20px;
    }
    .navbar-light .navbar-toggler {
        right: 20px;
        top: -100px;
    }
    header .navbar-light .navbar-nav .nav-link {
        font-size: 20px;
    }
    .bottom-footer-copyright {
        position: static;
    }
    .bottom-footer-copyright .footer-widget.widget-1 {
        text-align: center;
    }
    .banner-wrap .banner-video video {
        height: 180px;
        max-width: 100%;
    }
    .banner-wrap .banner-video:after {
        display: none;
    }
    .fragrance-follow .fragrance-follow-detail ul li img {
        max-width: 30px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content p {
        font-size: 30px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user img {
        max-width: 90px;
    }
    .afi-diy-section .afi-diy-detail .afi-diy-content .afi-diy-user {
        bottom: -35px;
    }
    .afi-diy-section .afi-diy-detail {
        margin: 0 auto 45px;
    }
    .edit-profile-picture .profile-details .profile-user {
        margin-bottom: 15px;
    }
    .fragrancecalculator-section .tabs-button-wrap .nav-link {
        margin: 0 0 10px;
        width: 49%;
    }
    .fragrance-calculator-fields {
        padding: 0;
    }
    .login-page .form-control {
        font-size: 18px;
    }
    .login-page label {
        font-size: 18px;
    }
    .login-page small {
        font-size: 15px;
    }
    .login-page .login-button .btn {
        max-width: 150px;
    }
    .ingredints-from .row .col-md-2 {
        width: 100%;
    }
    .category-info .img-right iframe {
        height: 230px;
    }
    body h2 {
        font-size: 22px;
    }
    .other-course-video .video-section {
        max-width: 90%;
    }
    .courses-section .other-course-video button.slick-arrow.slick-prev {
        left: 8px;
    }
    .other-course-video .video-discription {
        margin-bottom: 5px;
    }
    .other-course-video .video-discription p {
        margin-bottom: 0;
    }
    .search-wrap input[type=search]::placeholder {
        text-align: left;
    }
    .courses-section .video-section {
        border: 2px solid #c21626;
    }
    .fragrance-info-things .info-things-title h1 {
        font-size: 22px;
    }
    .fragrance-info-things .info-things-title {
        max-width: 300px;
        top: -30px;
        border: 2px solid #000;
    }
    .fragrance-info-things {
        margin: 55px 0 40px;
        padding: 45px 0 40px;
    }
    .courses-section .course-import .import-buttons ul li .btn span:first-child {
        font-size: 16px;
    }
    .courses-section .course-import .import-buttons ul li .btn {
        width: 60px;
    }
    .courses-leftbar .course-import .import-buttons ul li {
        padding: 0 3px;
    }
    .mien-canvas-graph {
        height: 210px;
    }
    .backbutton-space {
        width: 20% !important;
    }
    .table-responsive .table td,
    .table-responsive .table th {
        padding: 0.6rem;
    }
    .servay-table.table-responsive .table {
        width: 500px;
    }
}

@media (max-width: 415px) {
    .header-info h2 {
        font-size: 22px;
    }
    .header-info {
        margin: 19px 0px 25px 0 !important;
    }
}

@media (max-width: 400px) {
    .header-info h1 {
        padding-left: 36px;
    }
    .search-wrap {
        width: 100%;
    }
    .navbar-light .navbar-toggler {
        right: 10px;
        top: -90px;
    }
    .navbar.navbar-light {
        top: 110px;
    }
    .social-wrap .footer-widget.widget-3 a {
        margin: 0px 0 0px 1%;
    }
    .navbar-light .navbar-toggler {
        max-width: 40px;
    }
    .navbar-light .navbar-toggler {
        padding: 3px;
    }
    .banner-wrap .banner-info h3 {
        padding: 5px;
    }
    .header-info h2 {
        padding-left: 25px;
        margin: -59px 0 15px;
    }
    .top-header {
        padding: 10px 0px;
    }
    .course-item .thumbnail h4 {
        font-size: 20px;
        margin: 5px 0 5px;
    }
    .category-top-box {
        width: 100%;
        margin-bottom: 8px !important;
    }
    .sec-padding {
        padding: 10px 0px;
    }
}

.gray-bar {
    background: #a1a1a1;
    ;
    padding: 26px 16px;
}

.inner-ingredients-info-box {
    border-color: #ececec #ececec #ececec #c42625;
    -o-border-image: none;
    border-image: none;
    border-style: solid;
    border-width: 1px 1px 1px 3px;
    box-shadow: 0 0 2px 0 #eee;
    margin-bottom: 20px;
    padding: 15px 20px
}

.fragrance-details {
    margin: 30px 30px 30px 30px;
}

.text-limit {
    width: 438px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.avatar-user {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.colored-toast.swal2-icon-success {
    background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
    background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
    background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
    background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
    background-color: #87adbd !important;
}

.colored-toast .swal2-title {
    color: white;
}

.colored-toast .swal2-close {
    color: white;
}

.colored-toast .swal2-html-container {
    color: white;
}