.cat-wrap .gray-bar {
    background: #c42625;
    margin-bottom: 30px;
    padding: 26px 16px;
}
.cat-wrap .gray-bar h3 {
    color: #fff;
    margin: 0;
    margin-bottom: 0;
}
.inner-ingredients-info-box {
    border-color: #ececec #ececec #ececec #c42625;
    -o-border-image: none;
    border-image: none;
    border-style: solid;
    border-width: 1px 1px 1px 3px;
    box-shadow: 0 0 2px 0 #eee;
    margin-bottom: 20px;
    padding: 15px 20px
}


.cat-wrap .fragrance-details {
    margin: 30px 30px 30px 30px;
}

.text-limit {
    width: 438px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}
.inner-ingredients-info-box h5 a {
    color: #333;
    text-decoration: underline;
    font-weight: 400;
}
.inner-ingredients-info-box h5 a:hover {
    color: #c42625;
}
.inner-ingredients-info-box h5 {
    /*font-weight: 600;*/
}
.inner-ingredients-info-box{
    font-size: 20px;
}
