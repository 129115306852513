@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.App {
    text-align: center;
}
h3{
    font-weight: bold;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.input-b {
    background-color: red;
    color: white;
}

.cal_ {
    font-size: 55px;
    text-transform: uppercase;
    line-height: 60px;
    margin-bottom: 20px;
}

.candle-cal {
    padding: 91px 10px 10px 10px;
}

.hr {
    color: seashell;
    height: 5px;
    width: 50%;
    line-height: 50px;
    margin-left: auto;
    margin-right: auto;
}

.dot {
    border: 1px solid;
    border-radius: 50%;
    width: 91px;
    height: 276px;
}

.calculator-container {
    padding: 100px 0px 71px 0px;
}

.centerText {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.input-width {
    width: -webkit-fill-available;
    /* background-color: red; */
}

.card-header-1 {
    background-color: red;
}

.select {
    background-color: red;
}

.select-dropdown {
    border: none;
    width: -webkit-fill-available;
    font-size: larger;
}

.select-specific {
    padding: 5px;
    -webkit-appearance: none;
}

.tab {
    width: 100%;
    padding: 20px 0;
    background: primary-color;
    color: rgb(252, 8, 20);
    /* overflow: hidden; */
    text-align: center;
    flex-grow: 1;
    cursor: pointer;
    position: relative;
    text-decoration: none;
}

.tab:hover,
.tab:active {
    background: $tertiary-color;
}

.card-body-1 {
    background-color: #a1a1a1;
}
label{
    font-weight: bold;
}


/* 
.button{
  background-color: #f4f6fc;
  position: absolute;
  margin-top: 10px;
  margin-left: 143px;
  font: inherit;
  font-weight: bold;
  font-size: x-large;
  width: 165px;
} */


/* .button1{
  background-color: #f4f6fc;
  position: absolute;
  margin-top: 10px;
  margin-left: 388px;
  font: inherit;
  font-weight: bold;
  font-size: x-large;
  width: 165px;
}
.button2{
  background-color: #f4f6fc;
  position: absolute;
  margin-top: 10px;
  margin-left: 627px;
  font: inherit;
  font-weight: bold;
  font-size: x-large;
  width: 165px;
} */


/* .button3{
  background-color: #f4f6fc;
  position: absolute;
  margin-top: 10px;
  margin-left: 905px;
  font: inherit;
  font-weight: bold;
  font-size: x-large;
  width: 165px;
} */

.App-link {
    color: #61dafb;
}

.red-b-border-btn {
    font-size: 42px;
    text-align: center;
    padding: 16px 30px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    background: #c42625;
    color: #fff;
    border: 3px solid #000;
    text-decoration: none;
    line-height: 32px;
    min-width: 270px;
    border-radius: 8px;
    text-align: center;
}
.top-tile-with-btn .red-b-border-btn {
    margin-left: auto;
}
.courses-start {
    border: 3px solid #000;
    min-width: 350px;
    color: #000;
    text-decoration: none;
    font-size: 50px;
    font-family: 'BEBAS NEUE';
    display: inline-block;
    padding: 3px 0 0;
    text-align: center;
}
.courses-start:hover {
    color: #fff;
    background-color: #c42625;
    cursor:pointer;
}
.courses-inner-row {
    background-color: #dfdddd;
    padding: 30px 30px;
}
.course-title h3 {
    font-size: 60px;
    padding: 30px 30px;
    margin: 0;
    background-color: #fff;
}
.course-column strong {
    color: #000;
    font-size: 50px;
    font-family: 'BEBAS NEUE';
    font-weight: 500;
}
.checkbox-new input[type="radio"], .checkbox-new input[type="checkbox"] {
    height: 40px;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 2px;
    width: 40px;
    z-index: 1;
    cursor: pointer;
}
.checkbox-new label::after {
    background: transparent;
    border: 2px solid #000;
    content: "";
    height: 40px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 40px;
}
.checkbox-new input[type="checkbox"]:checked ~ label::after {
    border-color: #000;
    content: "\f00c";
    font: normal normal normal 30px/1 FontAwesome;
    text-align: center;
    line-height: 37px;
    background-color: #c42625;
    color: #fff;
}
.checkbox-new {
    position: relative;
}
.checkbox-new label {
    font-size: 35px;
    color: #000;
    font-family: 'BEBAS NEUE';
    line-height: 39px;
    padding-left: 63px;
}
.courses-inner-row .course-column {
    width: 33%;
}
.courses-inner-row .course-column.courses-time.text-center {
    width: 25%;
    border-right: 2px solid #bdbdbd;
    border-left: 2px solid #bdbdbd;
}
.course-column.firstcheck-box {
    width: 40%;
    padding-right: 25px;
}
.courses-detail-text p {
    font-size: 50px;
    font-family: 'BEBAS NEUE';
    line-height: 50px;
    margin: 0px 0 50px;
    border-top: 3px solid #000;
    padding-top: 50px;
    color: #fff;
}
.courses-section .video-section {
    border: 12px solid #c21626;
    background-color: #c21626;
}
.courses-inner-row .course-column:last-child {
    text-align: right;
}
.courses-bio {
    background: url("./assets/img/background-overlay-img.png") no-repeat;
    background-size: cover;
    padding: 20px 40px;
}
.red-b-border-btn:hover {
  background-color: #000;
  color: #fff;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
@media (max-width: 1700px) {
.red-b-border-btn {
    font-size: 28px;
    padding: 8px 30px;
    line-height: 26px;
    min-width: 250px;
}
.top-tile-with-btn h1 {
    font-size: 50px;
}
.courses-page {
    padding: 60px 0 0;
}
.checkbox-new label {
    font-size: 28px;
    line-height: 34px;
}
.course-column strong {
    font-size: 35px;
}
.courses-start {
    min-width: 260px;
    font-size: 34px;
}
}
@media (max-width: 1034px) {
.top-tile-with-btn h1 {
    font-size: 42px;
}
.red-b-border-btn {
    font-size: 22px;
    padding: 4px 30px;
    min-width: 200px;
    line-height: 22px;
}
.courses-detail-text p {
    font-size: 33px;
    line-height: 40px;
    margin: 0px 0 30px;
    padding-top: 40px;
}
.course-title h3 {
    font-size: 42px;
    padding: 20px 30px;
}
.checkbox-new label {
    font-size: 23px;
    line-height: 26px;
    padding-left: 50px;
}
.courses-start {
    min-width: 220px;
    font-size: 25px;
}
}
@media (max-width: 767px) {
.courses-inner-row {
    flex-wrap: wrap;
}
.course-column.firstcheck-box {
    width: 100%;
    padding-right: 0;
    min-height: 65px;
}
.courses-inner-row .course-column.courses-time.text-center {
    width: 35%;
    border-left: none;
    border-right: none;
    text-align: left !important;
}
.courses-inner-row .course-column:last-child {
    width: 65%;
}
.checkbox-new.pull-left {
    display: flex;
    align-items: center;
    min-height: 50px;
}
.courses-bio {
    padding: 0px 20px;
}
.courses-inner-row {
    padding: 15px 15px;
}
}
@media (max-width: 600px) {
.top-tile-with-btn {
    flex-wrap: wrap;
}
.top-tile-with-btn h1 {
    font-size: 42px;
    width: 100%;
    text-align: center;
}
.top-tile-with-btn .red-b-border-btn br {
    display: none;
}
.top-tile-with-btn .red-b-border-btn {
    width: 100%;
    padding: 15px 0;
}
.courses-detail-text p {
    font-size: 24px;
    line-height: 32px;
}
.course-title h3 {
    font-size: 34px;
    padding: 15px 20px;
}
.courses-start {
    min-width: inherit;
    font-size: 20px;
    padding: 4px 20px;
}
.course-column strong {
    font-size: 25px;
}
.checkbox-new.pull-left {
    margin-bottom: 30px;
}
}
.rounded-circle{
    border:1px solid;
    border-radius:50%;
    width:35px;
    height:35px;
}
.rounded-circle-front{
    border:1px solid;
    border-radius:50%;
    width:50px;
    height:50px;
}