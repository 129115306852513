.custom-pagination span button {
    border: 1px solid #c42625;
    display: inline-block;
    margin: 1px 2px;
    padding: 2px 10px;
    border-radius: 1px;
    font-size: 18px;
    font-weight: 400;
    background: #fff;
    color: #c42625;
}
.sidebar-mini.admin-dashboard .custom-pagination span button {
    font-size: 16px;
}

.custom-pagination span button:focus,
.page-link:focus{
    box-shadow: 0 0 0 0.2rem #c4262561 !important;
}

.custom-pagination span button.active,
.custom-pagination span button:hover {
    background-color: #c42625;
    color: #fff;
    text-decoration: none;
}
.cat-wrap ul.pagination li.page-item .page-link {
    font-size: 18px;
    background: #fff;
    padding: 2px 10px;
    color: #c42625 !important;
    border: 1px solid #c42625;
}
.cat-wrap ul.pagination li.page-item .page-link:hover,
.cat-wrap ul.pagination li.page-item .page-link.active{
    background: #c42625;
    color: #fff !important;
}
.custom-pagination span button[disabled] {
    cursor: not-allowed;
}

@media (max-width: 1199px){
    .cat-wrap .pagination-wrap .custom-pagination button, .cat-wrap ul.pagination li.page-item .page-link {
        font-size: 16px;
        padding: 2px 7px;
    }
    .inner-ingredients-info-box{
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .header-info h1 {
        padding-left: 60px;
    }
    .search-wrap button img {
        max-width: 30px;
    }
    .navbar-light .navbar-toggler {
        max-width: 50px;
    }
    .banner-wrap .banner-info {
        padding: 40px 12px;
    }
    .video-wrap.sec-padding {
        padding: 40px 0px 30px;
    }
    .sec-padding.aboutThis-course {
        padding: 40px 0px 0px;
    }
    .might-like {
        padding-bottom: 40px;
    }
    .img-right {
        text-align: center;
    }
    .about-course {
        padding-bottom: 15px;
    }
    .courses-wrap.main-categories h3 {
        font-size: 30px;
    }
    .video-wrap {
        padding: 40px 0px;
    }
    .courses-list {
        max-width: 100%;
        margin-left: auto;
        margin-top: 30px;
    }
    .lectures-list h2 {
        font-size: 30px;
    }
    .video-wrap .video-text {
        margin-bottom: 30px;
    }
    .video-wrap.sec-padding h3 {
        margin-bottom: 20px;
    }
    .video-wrap .video-text {
        margin-bottom: 30px;
    }
    .mt-30 {
        margin-top: 20px;
    }
    .course-info {
        max-width: 100%;
    }
    .course-info .course-name {
        flex-basis: 100%;
    }
    .course-info .course-des {
        flex-basis: 100%;
    }
    .pages-info p {
        font-size: 17px;
    }
    .lectures-list .heading-wrap {
        text-align: left;
        margin: 0 0px 30px;
    }
    .courses-wrap.main-categories h3 {
        text-align: center;
    }
    .courses-wrap.main-categories.main-resources {
        margin-bottom: 20px;
    }

    
    .category-info img {
        margin-top: 15px;
        max-width: 150px;
    }
    .category-info a {
        font-size: 25px;
    }
    .might-like .video {
        margin: 10px 0px;
    }
    .video-wrap.sec-padding .col-md-3 {
        flex: 0 0 100%;
    }
    .courseCategories-wrap .item {
        padding: 0px 25px 0px 0px;
    }
    .courseCategories-wrap .slick-next {
        right: 0px;
    }
    .banner-wrap {
        padding: 50px 0px;
        margin-bottom: 0;
    }
    .main-categories .course-item .thumbnail img {
        max-width: 175px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .main-categories h3 {
        margin-bottom: 15px;
    }
    .bottom-footer h2 {
        left: 10%;
    }
    .category-info img.dot {
        margin-right: auto;
    }
    .fragrance-info-wrap {
        margin-top: 25px;
    }
    html body .candle-cal.offset-md-4 .cal_ {
        margin-left: 0px !important;
        text-align: center;
    }
    .container.course-info {
        justify-content: center;
    }
    .header-info {
        max-width: 300px;
        margin: 0px 0px 0px 0;
    }
    .category-info img.dot {
        max-width: 230px;
        height: 230px;
    }
    .flex-directions.pagination-wrap {
        margin: 0;
        text-align: center;
    }
    .flex-directions.pagination-wrap .right-custom-search {
        max-width: 500px;
        margin: 0 auto;
    }
    .flex-directions.pagination-wrap .right-custom-search .btn.btn-primary {
        display: block;
        width: 100%;
    }
    .flex-directions.pagination-wrap .custom-pagination {
        margin-top: 30px;
    }
    .fragrance-details .col-md-6 {
        padding: 0px;
    }
    .right-custom-search .form-inline .input-wrap {
        width: 75%;
    }
    .right-custom-search .form-inline .button-wrap {
        width: 25%;
    }
}
@media (max-width: 574.98px){
    .col-md-3 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}